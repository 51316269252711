<template>
  <div>
    <ValidationObserver ref="contactObserver" tag="form">
      <ValidationProvider rules="required" name="Name" v-slot="{ errors, valid }">
        <b-field label="Name" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
          <b-input v-model="name" type="text">
          </b-input>
        </b-field>
      </ValidationProvider>

      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors, valid }">
        <b-field label="Email" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
          <b-input v-model="email" type="email">
          </b-input>
        </b-field>
      </ValidationProvider>

      <ValidationProvider rules="required" name="Nachricht" v-slot="{ errors, valid }">
        <b-field label="Nachricht" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
          <b-input v-model="message" maxlength="2000" type="textarea">
          </b-input>
        </b-field>
      </ValidationProvider>

      <div class="has-text-right">
        <b-button type="is-primary" :class="{ 'is-loading': buttonLoading }" v-on:click="tryMessage()">Absenden
        </b-button>
      </div>
    </ValidationObserver>
  </div>

</template>

<script>
import { axios } from '@/plugins/axios'
import * as Sentry from '@sentry/browser'

export default {
  name: 'Contact',
  data () {
    return {
      name: '',
      email: '',
      message: '',
      buttonLoading: false
    }
  },
  methods: {
    tryMessage: async function (arg) {
      const valid = await this.$refs.contactObserver.validate()
      if (valid) {
        this.buttonLoading = true
        axios.post('/contact', {
          name: this.name,
          email: this.email,
          message: this.message
        }).then(response => {
          if (response.data.errors.length === 0 && !response.data.generalError) {
            this.name = ''
            this.email = ''
            this.message = ''
            requestAnimationFrame(() => {
              this.$refs.contactObserver.reset()
            })
            this.alertSent()
          } else {
            var errorString = ''
            response.data.errors.forEach(function (item, index) {
              errorString += item.field + ': ' + item.defaultMessage + '<br>'
            }, this)
            this.alertCustomError(errorString)
          }
        }).catch(error => {
          this.alertCustomError(error)
          Sentry.setExtra('path', '/contact')
          Sentry.setExtra('name', this.name)
          Sentry.setExtra('email', this.email)
          Sentry.setExtra('message', this.message)
          Sentry.captureException(error)
        }).finally(() => {
          this.buttonLoading = false
        })
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Bitte korrigiere deine Daten.',
          position: 'is-top',
          type: 'is-danger',
          queue: false
        })
      }
    },
    alertSent () {
      this.$buefy.dialog.alert({
        title: 'Nachricht gesendet',
        message: 'Danke für deine Nachricht! Ich lese sie sobald wie möglich und antworte dir unter der angegebenen E-Mail-Adresse.',
        confirmText: 'Prima!',
        scroll: 'keep'
      })
    },
    alertCustomError (error) {
      this.$buefy.dialog.alert({
        title: 'Ein Fehler ist aufgetreten',
        message: 'Entschuldige bitte, es gab ein Problem und die Anfrage konnte nicht abgesendet werden.<br><br>' +
            'Schreib mir gern eine Mail an <b>anne@carnibits.de</b> - dieses Kontaktformular wurde das gleiche tun, wenn es kein Serverproblem gäbe. Tut mir' +
            ' echt Leid!<br><br><i>Das war übrigens der Fehler, falls es dich interessiert<br>' + error + '</i>',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        scroll: 'keep'
      })
    }
  }
}
</script>
