import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import VueAxios from './plugins/axios'
import './vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faUser, faUtensils, faThermometer, faDrumSteelpan, faTimesCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faQuestion, faCalendarAlt, faCat, faBriefcaseMedical, faDrumstickBite, faArrowRight,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faListAlt, faParachuteBox, faHandPointer, faForward, faBackward, faPlus, faMinus, faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueAnalytics from 'vue-analytics'

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faTimes, faCalendarAlt, faUser, faUtensils, faDrumstickBite, faDrumSteelpan,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faForward, faBackward, faQuestion, faPlus, faMinus, faCat, faBriefcaseMedical, faThermometer, faArrowRight, faTimesCircle,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faListAlt, faParachuteBox, faHandPointer)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})

Vue.use(VueAnalytics, {
  id: 'UA-163443320-1',
  router,
  set: [
    { field: 'anonymizeIp', value: true }
  ]
})

Sentry.init({
  dsn: 'https://ed6f064eb5e64d83abb57496728732f9@o358064.ingest.sentry.io/5197006',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })]
})

var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -74,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueAxios)

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
