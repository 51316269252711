<template>
  <div class="has-navbar-fixed-top">
    <section id="offerings" class="section ">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h2 class="section-title is-hidden-mobile">
            Fragebogen
          </h2>
          <h2 class="subsection-title is-hidden-tablet">
            Fragebogen
          </h2>
        </div>

        <b-steps ref="steps" id="steps" v-model="cat.other.activeStep" v-on:input="tryNext" v-on:change="persist" animated>
          <b-step-item label="Deine Katze" icon="cat">
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Deine Katze
              </h2>
            </div>
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <ValidationObserver ref="catObserver" tag="form">
                  <ValidationProvider rules="required" name="Name" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Name">
                      <b-input v-model="cat.general.name" type="text" placeholder="Name">
                      </b-input>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required|numeric" name="catAgeYears" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Alter">
                      <b-input v-model="cat.general.age" type="text" placeholder="ungefähres Alter in Jahren">
                      </b-input>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="Rasse" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Rasse">
                      <b-input v-model="cat.general.race" type="text" placeholder="Rasse" expanded></b-input>
                    </b-field>
                    <!--                    <b-field label="Rasse">-->
                    <!--                      <b-field :type="{ 'is-danger': errors[0]}" :message="errors" grouped>-->
                    <!--                        <b-input v-model="cat.general.race" type="text" placeholder="Rasse" expanded></b-input>-->
                    <!--                        <p class="control">-->
                    <!--                          <b-tooltip label="Tooltip right" position="is-right" animated type="is-accent">-->
                    <!--                            <b-button class="button is-accent" icon-left="question"/>-->
                    <!--                          </b-tooltip>-->
                    <!--                        </p>-->
                    <!--                      </b-field>-->
                    <!--                    </b-field>-->
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="Gewicht" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Gewicht in kg">
                      <b-input v-model="cat.general.weight" type="text" placeholder="Gewicht" expanded>
                      </b-input>
                    </b-field>
                  </ValidationProvider>

                  <b-field label="Statur">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <p class="level-item">
                          <span class="">Mager</span>
                        </p>
                      </div>
                      <p class="level-item padding-30-h">
                        <b-slider v-model="cat.general.bodyFatness" size="is-large" bigger-slider-focus :tooltip="false"></b-slider>
                      </p>
                      <div class="level-right">
                        <p class="level-item">
                          <span class="">Fett</span>
                        </p>
                      </div>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catSex" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Geschlecht">
                      <div>
                        <b-radio v-model="cat.general.sex" name="catSex" native-value="male"> männlich</b-radio>
                        <b-radio v-model="cat.general.sex" name="catSex" native-value="female"> weiblich</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="catFertility" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Fruchtbarkeit">
                      <div>
                        <b-radio v-model="cat.general.fertility" name="catFertility" native-value="working"> intakt</b-radio>
                        <b-radio v-model="cat.general.fertility" name="catFertility" native-value="castrated"> kastriert</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="castrationPlanned" v-slot="{ errors }" slim v-if="cat.general.fertility==='working'">
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Kastration geplant?">
                      <div>
                        <b-radio v-model="cat.general.castrationPlanned" name="castrationPlanned" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.general.castrationPlanned" name="castrationPlanned" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="catPregnant" v-slot="{ errors }" slim v-if="cat.general.fertility==='working' && cat.general.sex==='female'">
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Aktuell trächtig?">
                      <div>
                        <b-radio v-model="cat.general.pregnant" name="catPregnant" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.general.pregnant" name="catPregnant" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="catNursing" v-slot="{ errors }" slim v-if="cat.general.fertility==='working' && cat.general.sex==='female'">
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Aktuell säugend?">
                      <div>
                        <b-radio v-model="cat.general.nursing" name="catNursing" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.general.nursing" name="catNursing" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider rules="required" name="catActivity" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Aktivität">
                      <div>
                        <b-radio v-model="cat.general.activity" name="catActivity" native-value="inside"> Wohnungskatze</b-radio>
                        <b-radio v-model="cat.general.activity" name="catActivity" native-value="outside"> Freigänger</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field>
                    <div class="level">
                      <div class="level-left">
                        <p class="level-item has-text-left">
                          <span class="is-hidden-tablet">1 = Couch-Potato</span>
                          <span class="is-hidden-mobile">Couch-Potato</span>
                        </p>
                      </div>
                      <p class="level-item padding-30-h">
                        <b-slider v-model="cat.general.activityLevel" :min="1" :max="5" bigger-slider-focus :tooltip="false">
                          <b-slider-tick :value="1">1</b-slider-tick>
                          <b-slider-tick :value="2">2</b-slider-tick>
                          <b-slider-tick :value="3">3</b-slider-tick>
                          <b-slider-tick :value="4">4</b-slider-tick>
                          <b-slider-tick :value="5">5</b-slider-tick>
                        </b-slider>
                      </p>
                      <div class="level-right">
                        <p class="level-item has-text-centered">
                          <span class="is-hidden-tablet">5 = Hohe körperliche und<br>geistige Auslastung</span>
                          <span class="is-hidden-mobile">Hohe körperliche und<br>geistige Auslastung</span>
                        </p>
                      </div>
                    </div>
                  </b-field>

                  <b-field :label="'Neigt ' + catName + ' zum Mäkeln?'">
                    <div class="padding-30-h">
                      <b-slider v-model="cat.general.carping" :min="1" :max="4" bigger-slider-focus :tooltip="false">
                        <b-slider-tick :value="1">nein</b-slider-tick>
                        <b-slider-tick :value="2">eher nein</b-slider-tick>
                        <b-slider-tick :value="3">eher ja</b-slider-tick>
                        <b-slider-tick :value="4">ja</b-slider-tick>
                      </b-slider>
                    </div>
                  </b-field>

                  <b-field label="Charakterzüge">
                    <div class="columns is-multiline tag-container">
                      <div class="column tags is-narrow" v-for="(option, index) in form.characterOptions" v-bind:key="index">
                        <b-checkbox-button v-model="cat.general.characterTraits" :native-value="option">
                          <span>{{ option }}</span>
                        </b-checkbox-button>
                      </div>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catOtherAnimals" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Leben weitere Tiere im Haushalt?">
                      <div>
                        <b-radio v-model="cat.general.otherAnimals" name="catOtherAnimals" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.general.otherAnimals" name="catOtherAnimals" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.general.otherAnimals==='yes'">
                    <div>
                      <p> Wie viele? Welche? Seit wann?</p>
                      <b-input v-model="cat.general.otherAnimalsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <b-field :label="'Seit wann lebt ' + catName+ ' bei dir?'">
                    <b-input v-model="cat.general.sinceWhen" type="textarea"></b-input>
                  </b-field>

                  <b-field :label="'Bilder von ' + (this.cat.general.name === '' ? 'deiner Katze' : this.cat.general.name) + ''">
                    <div class="has-text-centered">
                      <p class="has-text-left">Bitte lade, falls vorhanden, je ein Bild deiner Katze von oben und von der Seite hoch. Falls du keine zur Hand hast, kannst du sie
                        mir auch später schicken. Die Bilder helfen mir dabei, die Statur der Katze einzuschätzen.</p>
                      <b-upload class="" v-model="images" drag-drop multiple>
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="upload" size="is-large"></b-icon>
                          </p>
                          <p>Zum Upload hier klicken oder Bilder per Drag&Drop reinziehen</p>
                        </div>
                      </b-upload>
                      <div class="tags">
                      <span v-for="(file, index) in images" :key="index" class="tag is-primary">
                        {{file.name}}
                          <button class="delete is-small" type="button" @click="deleteCatImage(index)">
                          </button>
                      </span>
                      </div>
                    </div>
                  </b-field>

                  <b-field label="Sonstiges">
                    <b-input v-model="cat.general.other" type="textarea"></b-input>
                  </b-field>
                </ValidationObserver>
              </div>
            </div>
          </b-step-item>

          <b-step-item label="Allgemeinzustand" icon="briefcase-medical">
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Allgemeinzustand
              </h2>
            </div>
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <ValidationObserver ref="generalCatStateObserver" tag="form">
                  <b-field label="Haut- und Fellbeschaffenheit">
                    <div class="columns is-multiline tag-container">
                      <div class="column tags is-narrow" v-for="(option, index) in form.furOptions" v-bind:key="index">
                        <b-checkbox-button v-model="cat.health.furTraits" :native-value="option">
                          <span>{{ option }}</span>
                        </b-checkbox-button>
                      </div>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catTeethProblems" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Probleme mit Zähnen oder Zahnfleisch?">
                      <div>
                        <b-radio v-model="cat.health.teethProblems" name="catTeethProblems" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.health.teethProblems" name="catTeethProblems" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.health.teethProblems==='yes'">
                    <div>
                      <p> Bitte beschreibe die Probleme. </p>
                      <b-input v-model="cat.health.teethProblemsDescription" type="textarea" v-if="cat.health.teethProblems==='yes'"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catShitting" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Wie häufig wird Kot abgesetzt?">
                      <div>
                        <b-radio v-model="cat.health.shitting" name="catShitting" native-value="lessThanOne">
                          &lt;1x / Tag
                        </b-radio>
                        <b-radio v-model="cat.health.shitting" name="catShitting" native-value="oneToTwo">
                          1-2x / Tag
                        </b-radio>
                        <b-radio v-model="cat.health.shitting" name="catShitting" native-value="moreThanTwo">
                          &gt;2x / Tag
                        </b-radio>
                        <b-radio v-model="cat.health.shitting" name="catShitting" native-value="unclear">
                          nicht beurteilbar
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>

                  <b-field label="Kotbeschaffenheit">
                    <div class="columns is-multiline tag-container">
                      <div class="column tags is-narrow" v-for="(option, index) in form.shitOptions" v-bind:key="index">
                        <div>
                          <b-checkbox-button v-model="cat.health.shitTraits" :native-value="option">
                            <span>{{ option }}</span>
                          </b-checkbox-button>
                        </div>
                      </div>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catShittingProblems" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Auffälligkeiten beim Kot oder Urin ausscheiden?">
                      <div>
                        <b-radio v-model="cat.health.otherShittingProblems" name="catShittingProblems" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.health.otherShittingProblems" name="catShittingProblems" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.health.otherShittingProblems==='yes'">
                    <div>
                      <p>Bitte beschreibe die Auffälligkeiten.</p>
                      <b-input v-model="cat.health.otherShittingProblemsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catFeedingProblems" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Auffälligkeiten vor, während oder nach dem Fressen?">
                      <div>
                        <b-radio v-model="cat.health.otherFeedingsProblems" name="catFeedingProblems" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.health.otherFeedingsProblems" name="catFeedingProblems" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.health.otherFeedingsProblems==='yes'">
                    <div>
                      <p>Bitte beschreibe die Auffälligkeiten.</p>
                      <b-input v-model="cat.health.otherFeedingsProblemsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catProblems" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Sonstige Auffälligkeiten?">
                      <div>
                        <b-radio v-model="cat.health.otherProblems" name="catProblems" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.health.otherProblems" name="catProblems" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.health.otherProblems==='yes'">
                    <div>
                      <p>Bitte beschreibe die Auffälligkeiten.</p>
                      <b-input v-model="cat.health.otherProblemsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                </ValidationObserver>
              </div>
            </div>
          </b-step-item>

          <b-step-item label="Fütterung" icon="utensils">
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Fütterung
              </h2>
            </div>
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <ValidationObserver ref="feedingObserver" tag="form">
                  <ValidationProvider rules="required" name="feedingDry" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Trockenfutter?">
                      <div>
                        <b-radio v-model="cat.feeding.dry" name="feedingDry" native-value="yes"> Ja, wird gefüttert</b-radio>
                        <b-radio v-model="cat.feeding.dry" name="feedingDry" native-value="no"> Nein
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.dry==='yes'">
                    <div>
                      <p> Bitte beschreibe, welches Trockenfutter gefüttert wird. </p>
                      <FeedingEntry @dataChanged="feedingEntryChanged" foodType="dry" :entries="cat.feeding.dryFood"></FeedingEntry>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="feedingWet" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Nassfutter?">
                      <div>
                        <b-radio v-model="cat.feeding.wet" name="feedingWet" native-value="yes"> Ja, wird gefüttert</b-radio>
                        <b-radio v-model="cat.feeding.wet" name="feedingWet" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.wet==='yes'">
                    <div>
                      <p> Bitte beschreibe, welches Nassfutter gefüttert wird. </p>
                      <FeedingEntry @dataChanged="feedingEntryChanged" foodType="wet" :entries="cat.feeding.wetFood"></FeedingEntry>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="feedingPackageBarf" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Fertig-BARF?">
                      <div>
                        <b-radio v-model="cat.feeding.packageBarf" name="feedingPackageBarf" native-value="yes">
                          Ja, wird gefüttert
                        </b-radio>
                        <b-radio v-model="cat.feeding.packageBarf" name="feedingPackageBarf" native-value="no">
                          Nein
                        </b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.packageBarf==='yes'">
                    <div>
                      <p> Bitte beschreibe, welches Fertig-BARF gefüttert wird. </p>
                      <FeedingEntry @dataChanged="feedingEntryChanged" foodType="packageBarf" :entries="cat.feeding.packageBarfFood"></FeedingEntry>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catCustomBarf" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Wird selbst zusammengestelltes Futter gefüttert?">
                      <div>
                        <b-radio v-model="cat.feeding.customBarf" name="catCustomBarf" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.feeding.customBarf" name="catCustomBarf" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.customBarf==='yes'">
                    <div>
                      <p> Was beinhaltet eine Portion? Wie sieht das Rezept aus?</p>
                      <b-input v-model="cat.feeding.customBarfDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catWholeAnimals" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Werden ganze Beutetiere gefüttert?">
                      <div>
                        <b-radio v-model="cat.feeding.wholeAnimals" name="catWholeAnimals" native-value="yes"> Ja, werden gefüttert</b-radio>
                        <b-radio v-model="cat.feeding.wholeAnimals" name="catWholeAnimals" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.wholeAnimals==='yes'">
                    <div>
                      <p>Welche? Wie viele? Wie oft?</p>
                      <b-input v-model="cat.feeding.wholeAnimalsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catTreats" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Werden Leckerlies gefüttert?">
                      <div>
                        <b-radio v-model="cat.feeding.treats" name="catTreats" native-value="yes"> Ja, werden gefüttert</b-radio>
                        <b-radio v-model="cat.feeding.treats" name="catTreats" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.treats==='yes'">
                    <div>
                      <p>Welche oder was? Wie viele? Wie oft?</p>
                      <b-input v-model="cat.feeding.treatsDescription" type="textarea"></b-input>
                    </div>
                  </b-field>

                  <ValidationProvider rules="required" name="catFoodIntolerance" v-slot="{ errors }" slim>
                    <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Besteht eine Nahrungsmittelunverträglichkeit oder eine Nahrugsmittelallergie?">
                      <div>
                        <b-radio v-model="cat.feeding.intolerance" name="catFoodIntolerance" native-value="yes"> Ja</b-radio>
                        <b-radio v-model="cat.feeding.intolerance" name="catFoodIntolerance" native-value="no"> Nein</b-radio>
                      </div>
                    </b-field>
                  </ValidationProvider>
                  <b-field v-if="cat.feeding.intolerance==='yes'">
                    <div>
                      <p>Welche?</p>
                      <b-input v-model="cat.feeding.intoleranceDescription" type="textarea"></b-input>
                      <p>Wie wurde dies festgestellt?</p>
                      <b-input v-model="cat.feeding.intoleranceDetection" type="textarea"></b-input>
                    </div>
                  </b-field>
                </ValidationObserver>
              </div>
            </div>
          </b-step-item>

          <b-step-item label="Erkrankungen" icon="thermometer">
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Erkrankungen
              </h2>
            </div>
            <ValidationObserver ref="illnessesObserver" tag="form">
              <ValidationProvider rules="required" name="catIll" v-slot="{ errors }" slim>
                <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Bestehen ärztlich diagnostizierte Erkrankungen?">
                  <div>
                    <b-radio v-model="cat.illnesses.ill" name="catIll" native-value="no">
                      Nein, ich bestätige, dass {{catName}} an keiner bisher diagnostizierten, mir bekannten Erkrankung leidet
                    </b-radio>
                    <br>
                    <b-radio v-model="cat.illnesses.ill" name="catIll" native-value="yes">
                      Ja, {{catName}} leidet an ärztlich diagnostizierten Erkrankungen
                    </b-radio>
                  </div>
                </b-field>
              </ValidationProvider>

              <div v-if="cat.illnesses.ill==='yes'">
                <b-field label="Welche?">
                  <b-input v-model="cat.illnesses.illnessDescription" type="textarea"></b-input>
                </b-field>

                <b-field label="Seit wann besteht die Erkrankung bzw. wann wurde sie festgestellt?">
                  <b-input v-model="cat.illnesses.illnessDetection" type="textarea"></b-input>
                </b-field>

                <ValidationProvider rules="required" name="catDrugs" v-slot="{ errors }" slim>
                  <b-field :type="{ 'is-danger': errors[0]}" :message="errors" label="Müssen regelmäßig Medikamente verabreicht werden?">
                    <div>
                      <b-radio v-model="cat.illnesses.drugs" name="catDrugs" native-value="yes">Ja</b-radio>
                      <b-radio v-model="cat.illnesses.drugs" name="catDrugs" native-value="no">Nein</b-radio>
                    </div>
                  </b-field>
                </ValidationProvider>

                <b-field label="Welche? Wie oft? In welcher Dosierung?" v-if="cat.illnesses.drugs==='yes'">
                  <b-input v-model="cat.illnesses.drugsDescription" type="textarea"></b-input>
                </b-field>

                <ValidationProvider rules="required" name="catTherapy" v-slot="{ errors }" slim>
                  <b-field :type="{ 'is-danger': errors[0]}" :message="errors" :label="'Ist ' +catName+' aktuell in Behandlung?'">
                    <div>
                      <b-radio v-model="cat.illnesses.therapy" name="catTherapy" native-value="yes">Ja</b-radio>
                      <b-radio v-model="cat.illnesses.therapy" name="catTherapy" native-value="no">Nein</b-radio>
                    </div>
                  </b-field>
                </ValidationProvider>
                <b-field :label="'Gibt es ärztliche Befunde zu ' +catName+'?'">
                  <div class="has-text-centered">
                    <p class="has-text-left">Bitte lade ärtzliche Befunde, falls vorhanden, hier hoch. Die Befunde helfen mir dabei, den gesundheitliche Zustand deiner Katze besser
                      einzuschätzen und daraus die richtigen Schlüsse für die Ernährungsberatung zu ziehen.</p>
                    <b-upload class="" v-model="findings" multiple drag-drop>
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Zum Upload hier klicken oder Befunde per Drag&Drop reinziehen</p>
                      </div>
                    </b-upload>
                    <div class="tags">
                      <span v-for="(file, index) in findings" :key="index" class="tag is-primary">
                        {{file.name}}
                          <button class="delete is-small" type="button" @click="deleteFinding(index)">
                          </button>
                      </span>
                    </div>
                  </div>
                </b-field>
              </div>
            </ValidationObserver>
          </b-step-item>

          <b-step-item label="Abschicken" icon="check">
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <div class="section-title-wrapper has-text-centered is-hidden-mobile">
                  <h2 class="subsection-title">
                    Abschicken
                  </h2>
                </div>

                <ValidationObserver ref="sendObserver" tag="form">
                  Willst du mir noch etwas mitteilen, oder soll ich etwas zu deinen Angaben beachten? Schreib es gern
                  gleich hier.
                  <b-input type="textarea" v-model="cat.other.notes"></b-input>

                  <div class="margin-top margin-bottom has-text-centered">
                    <b-button type="is-primary" @click="trySend" :class="{ 'is-loading': sendButtonLoading }">Absenden</b-button>
                  </div>
                  <p>
                    <i>Bitte hab Geduld - das Senden kann ein bisschen dauern, da auch deine Dateien hochgeladen werden, falls du welche angegeben hast.</i>
                  </p>
                </ValidationObserver>
              </div>
            </div>

          </b-step-item>

          <template slot="navigation" slot-scope="{previous, next}">
            <div class="container has-text-centered padding-20">
              <b-button class="step-control" outlined type="is-primary" icon-left="backward" v-if="!previous.disabled" @click.prevent="previous.action"
                        v-scroll-to="'#steps'">
                Zurück
              </b-button>
              <b-button class="step-control" outlined type="is-primary" icon-right="forward" v-if="!next.disabled" @click.prevent="next.action"
                        v-scroll-to="'#steps'">
                Weiter
              </b-button>
            </div>
          </template>
        </b-steps>
      </div>
    </section>
  </div>
</template>

<script>
import FeedingEntry from '@/components/FeedingEntry'
import ContinueQuestionnaireModal from '@/components/ContinueQuestionnaireModal'
import { axios } from '@/plugins/axios'
import * as Sentry from '@sentry/browser'

export default {
  name: 'Questionnaire',
  data () {
    return {
      form: {
        furOptions: ['glänzend', 'weich', 'schuppig', 'fettig', 'stumpf', 'fettiger Schwanzansatz', 'kahle Stellen'],
        shitOptions: ['hart', 'fest-geformt', 'breiig', 'schleimig', 'dünnflüssig', 'wässrig', 'blutig', 'fettig', 'mit Schleimauflagerungen'],
        characterOptions: ['eigenwillig', 'depressiv', 'energiegeladen', 'entspannt', 'defensiv', 'distanziert', 'dominant', 'lieb', 'aggressiv', 'anhänglich', 'ängstlich', 'aufmerksam', 'ausgeglichen',
          'begriffstutzig', 'bequem', 'sensibel', 'fordernd', 'kinderlieb', 'misstrauisch', 'scheu', 'unsicher', 'ruhig', 'frech', 'freundlich', 'verfressen', 'furchtlos', 'intelligent', 'vorsichtig',
          'unterwürfig', 'genügsam', 'neugierig', 'ruhelos', 'gesprächig', 'geschickt', 'tollpatschig', 'sozial', 'verschmust']
      },
      cat: {
        general: {
          name: '',
          age: '',
          race: '',
          weight: '',
          bodyFatness: 50,
          activity: '',
          activityLevel: 3,
          sinceWhen: '',
          sex: '',
          fertility: '',
          pregnant: '',
          nursing: '',
          castrationPlanned: '',
          carping: 1,
          characterTraits: [],
          otherAnimals: '',
          otherAnimalsDescription: '',
          other: ''
        },
        health: {
          furTraits: [],
          teethProblems: '',
          teethProblemsDescription: '',
          shitting: '',
          shitTraits: [],
          otherShittingProblems: '',
          otherShittingProblemsDescription: '',
          otherFeedingsProblems: '',
          otherFeedingsProblemsDescription: '',
          otherProblems: '',
          otherProblemsDescription: ''
        },
        feeding: {
          dry: '',
          dryFood: [],
          wet: '',
          wetFood: [],
          packageBarf: '',
          packageBarfFood: [],
          customBarf: '',
          customBarfDescription: '',
          wholeAnimals: '',
          wholeAnimalsDescription: '',
          treats: '',
          treatsDescription: '',
          intolerance: '',
          intoleranceDescription: '',
          intoleranceDetection: ''
        },
        illnesses: {
          ill: '',
          illnessDescription: '',
          illnessDetection: '',
          drugs: '',
          drugsDescription: '',
          therapy: ''
        },
        other: {
          notes: '',
          activeStep: 0,
          lastStep: 0,
          orderId: this.$route.params.orderId
        }
      },
      sendButtonLoading: false,
      images: [],
      findings: []
    }
  },
  components: {
    FeedingEntry
  },
  mounted () {
    const cat = localStorage.getItem('cat')
    if (cat) {
      this.$buefy.modal.open({
        component: ContinueQuestionnaireModal,
        parent: this,
        'can-cancel': false,
        events: {
          continueForm: doContinue => {
            if (doContinue) {
              this.cat = JSON.parse(cat)
              this.cat.other.activeStep = this.cat.other.lastStep
            } else {
              localStorage.removeItem('cat')
            }
          }
        }
      })
    }
  },
  methods: {
    trySend: async function () {
      const isValid = await this.$refs.sendObserver.validate()
      if (isValid) {
        this.sendButtonLoading = true
        const data = new FormData()

        for (const img of this.images) {
          data.append('documents', img)
        }
        for (const finding of this.findings) {
          data.append('documents', finding)
        }
        data.append('questionnaire', JSON.stringify(this.cat))

        Sentry.setExtra('path', '/order/questionnaire')
        Sentry.setExtra('cat', this.cat)
        axios.post('/order/questionnaire', data).then(response => {
          if (response.data.errorMessage === '') {
            localStorage.removeItem('cat')
            this.$router.push('/').catch(err => {
              this.ignore = err
            })
            window.scrollTo(0, 0)
            this.$buefy.dialog.alert({
              title: 'Fragebogen erfolgreich gesendet!',
              message: 'Wunderbar, der Fragebogen ist unterwegs.' +
                  'Falls du zwischenzeitlich Fragen hast, oder es Probleme mit der Bestellung gibt, wende dich jederzeit an mich unter <b>anne@carnibits.de</b>.',
              confirmText: 'Ok',
              scroll: 'keep',
              type: 'is-success'
            })
          } else {
            this.alertCustomError(response.data.errorMessage)
            Sentry.captureMessage('Error from Backend: ' + response.data.errorMessage)
          }
        }).catch(error => {
          this.alertCustomError(error)
          Sentry.captureException(error)
        }).finally(() => (this.sendButtonLoading = false))
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Bitte korrigiere deine Daten.',
          position: 'is-bottom',
          type: 'is-danger',
          queue: false
        })
      }
    },
    persist: function persist () {
      if (this.cat.other.lastStep !== this.cat.other.activeStep) {
        console.log('persist')
        const other = JSON.stringify(this.other)
        localStorage.setItem('other', other)
        const cat = JSON.stringify(this.cat)
        localStorage.setItem('cat', cat)
      }
    },
    deleteCatImage: function deleteCatImage (index) {
      this.images.splice(index, 1)
    },
    deleteFinding: function deleteFinding (index) {
      this.findings.splice(index, 1)
    },
    feedingEntryChanged: function feedingEntryChanged (data, foodType) {
      if (foodType === 'dry') {
        this.cat.feeding.dryFood = data
      } else if (foodType === 'wet') {
        this.cat.feeding.wetFood = data
      } else if (foodType === 'packageBarf') {
        this.cat.feeding.packageBarfFood = data
      }
    },
    alertCustomError (error) {
      this.$buefy.dialog.alert({
        title: 'Ein Fehler ist aufgetreten',
        message: 'Entschuldige bitte, es gab ein Problem und der Fragebogen konnte nicht abgesendet werden.<br><br>' +
            'Deine Eingaben sind lokal bei dir im Browser gespeichert, du musst also nicht nochmal alles ausfüllen. Ruf bitte später den Link wieder auf, dann solltest du die Bearbeitung fortsetzen können. ' +
            'Ansonsten schreib mir gern eine Mail an <b>anne@carnibits.de</b>. Tut mir' +
            ' echt Leid!<br><br><i>Das war übrigens der Fehler, falls es dich interessiert<br>' + error + '</i>',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
        scroll: 'keep'
      })
    },
    tryNext: async function (arg) {
      let valid = true
      if (this.cat.other.lastStep === 0) {
        valid = await this.$refs.catObserver.validate()
      } else if (this.cat.other.lastStep === 1) {
        valid = await this.$refs.generalCatStateObserver.validate()
      } else if (this.cat.other.lastStep === 2) {
        valid = await this.$refs.feedingObserver.validate()
      } else if (this.cat.other.lastStep === 3) {
        valid = await this.$refs.illnessesObserver.validate()
      }
      if (valid) {
        this.cat.other.lastStep = this.cat.other.activeStep
      } else {
        this.cat.other.activeStep = this.cat.other.lastStep
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Bitte korrigiere deine Daten.',
          position: 'is-bottom',
          type: 'is-danger',
          queue: false
        })
      }
    }
  },
  computed: {
    catName: function () {
      return this.cat.general.name === '' ? 'die Katze' : this.cat.general.name
    }
  },
  created () {
    this.$emit('useFixedNavbar', true)
  }
}
</script>

<style lang="scss">
  @import "../app.scss";

  .section {
    background-color: $secondary;
  }
</style>

<style scoped lang="scss">
  .tags:not(:last-child) {
    padding: 0.15rem;
    margin-bottom: 0.15rem;
  }

  .tags {
    padding: 0.15rem;
    margin-bottom: 0.15rem;
  }

  .tag-container {
    padding: 1rem 0;
  }

  .step-control {
    margin-left: 1rem;
    margin-right: 1rem;
  }
</style>
