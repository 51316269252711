import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Consultation from '@/views/Consultation'
import Calculators from '@/views/Calculators'
import Questionnaire from '@/views/Questionnaire'
import OrderConsultation from '@/views/OrderConsultation'
import Imprint from '@/views/Imprint'
import Privacy from '@/views/Privacy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/consultation',
    name: 'Consultation',
    component: Consultation,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/calculators',
    name: 'Calculators',
    component: Calculators,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/questionnaire/:orderId',
    name: 'Questionnaire',
    component: Questionnaire,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/imprint',
    name: 'Impressum',
    component: Imprint,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/order-consultation/:type',
    name: 'order-consultation',
    component: OrderConsultation,
    meta: {
      isPublic: true
    }
  },
  {
    path: '*',
    name: '404',
    component: Home,
    meta: {
      isPublic: true
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
