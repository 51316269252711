<template>
  <div class="has-navbar-fixed-top">
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged-no-subtitle">
            Einstellungen zur Privatsphäre
          </h1>
        </div>
        <div>
          <p>Wir nutzen Google Analytics um anonymisierte Nutzungsdaten zu bekommen. Damit können wir verstehen, ob die Seite so funktioniert wie wir geplant haben, oder ob
            bestimmte Bereiche gar nicht gefunden werden.</p><br>
          <p><strong>Standardmäßig ist Google Analytics komplett ausgeschalten - erst nachdem zu zustimmst wird es aktiv.</strong></p><br>
          <p>Weitere Informationen zum Datenschutz findest du in der <a :href="`${publicPath}docs/Datenschutzerklärung_11_04_2020.pdf`">Datenschutzerklärung</a></p><br>
          <div class="field">
            <p><strong>Ich erlaube die anonymisierte Verwendung meiner Nutzungsdaten auf dieser Webseite.</strong></p>
            <b-switch v-model=" privacy.gaOk" true-value="Ja" false-value="Nein" @input="changePrivacy">
              {{ privacy.gaOk }}
            </b-switch>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Privacy',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      privacy: {
        accepted: false,
        gaOk: 'Nein'
      }
    }
  },
  methods: {
    changePrivacy () {
      const privacy = JSON.stringify(this.privacy)
      localStorage.setItem('privacy', privacy)

      if (this.privacy.gaOk) {
        this.$ga.enable()
      } else {
        this.$ga.disable()
      }
    }
  },
  created () {
    this.$emit('useFixedNavbar', true)

    const privacy = localStorage.getItem('privacy')
    if (privacy) {
      this.privacy = JSON.parse(privacy)
    }
    this.gaSwitched = this.privacy.gaOk === 'Ja'
  }
}
</script>

<style>

</style>
