<template>
  <div class="has-background-secondary panel is-primary">
    <p class="panel-heading has-text-centered is-size-3">
      Bearbeitung fortsetzen?
    </p>
    <div class="panel-block">
      <div class="container column is-four-fifths">
        Es sind bereits Daten bei dir lokal gespeichert. Möchtest du mit den gespeicherten Daten weiterarbeiten, oder von vorn beginnen?

        <b-field grouped>
          <p class="control">
            <b-button type="is-danger" @click="newForm">Neu anfangen</b-button>
          </p>
          <p class="control">
            <b-button type="is-primary" @click="continueForm">Fortsetzen</b-button>
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  data () {
    return {}
  },
  methods: {
    newForm: function newForm () {
      this.$emit('continueForm', false)
      this.$emit('close')
    },
    continueForm: function continueForm () {
      this.$emit('continueForm', true)
      this.$emit('close')
    }
  }
}
</script>
