<template>
  <div class="has-navbar-fixed-top">
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged is-hidden-mobile">
            Ernährungsberatung
          </h1>
          <h1 class="title-winged is-hidden-tablet">
            Ernährungs-beratung
          </h1>
          <h4 class="subtitle">Darum kann sie sinnvoll sein</h4>
        </div>
        <div class="columns">
          <div class="column is-5">
            Jeder kann ohne Ernährungsberater anfangen die Ernährung seiner Katze(n) zu hinterfragen, sich die Wissensgrundlagen anzueignen und eigenständig die Ernährung
            umzustellen. <br>Wenn du wissen möchtest auf was man bei Fertigfutter achten muss oder wie man das Futter gar selbst zusammenmischt, dann kommst du nicht daran vorbei,
            dich
            intensiv mit diesem Thema zu beschäftigen und viel Zeit zu investieren. Leider sind heutzutage unsere Informationsquellen so vielfältig, dass man mitunter den Überblick
            verliert oder verunsichert wird.<br>
            Hier kommen die Ernährungsberater ins Spiel. Ihr Einsatz kann sinnvoll sein, wenn du verunsichert bist, nicht weisst wie und wo du anfangen sollst oder aber aus
            bestimmten Gründen keine Zeit oder Lust hast dir das Wissen selbst anzueignen.
          </div>
        </div>
      </div>
    </section>

    <section id="timeline" class="section section-accent">
      <div class="container ">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged-no-subtitle on-accent"> Ablauf </h1>
        </div>

        <div class="columns is-vcentered pt-30">
          <div class="column is-10 is-offset-1">
            <div class="timeline">
              <div class="timeline-item">
                <div class="timeline-icon">
                  <b-icon pack="fas" icon="hand-pointer"></b-icon>
                </div>
                <div class="timeline-content left">
                  <div class="content-body">
                    <div class="timeline-text">
                      <img class="timeline-avatar" src="../assets/icons/004-kitty-3.svg" alt="">
                      <strong>Leistungsauswahl</strong>
                      <div>
                        Schau dir in Ruhe an, welche <a v-scroll-to="'#offerings'">Leistungspakete</a> ich für dich zur Auswahl habe und such dir dann das passende Angebot aus. Wenn du dich entschieden hast,
                        bestellst du einfach dein ausgewähltes Paket. Du erhältst dann einen Link zum Fragebogen und die Rechnung per Mail.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item">
                <div class="timeline-icon">
                  <b-icon pack="fas" icon="list-alt"></b-icon>
                </div>
                <div class="timeline-content right">
                  <div class="content-body">
                    <img class="timeline-avatar is-hidden-mobile" src="../assets/icons/002-kitty-1-flip.svg" alt="">
                    <img class="timeline-avatar is-hidden-tablet" src="../assets/icons/002-kitty-1.svg" alt="">
                    <div class="timeline-text">
                      <strong>Fragebogen ausfüllen</strong>
                      <div>
                        Den Fragebogen kannst du in aller Ruhe im Browser ausfüllen. Hast du alle Informationen eingetragen und überprüft sendest du ihn ab. Er gelangt
                        dann direkt zu mir.
                        Wenn es noch etwas zu klären gibt, melde ich mich bei dir. Ist alles klar und die Rechnung beglichen, beginnt meine eigentliche Arbeit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item">

                <div class="timeline-icon">
                  <b-icon pack="fas" icon="parachute-box"></b-icon>
                </div>
                <div class="timeline-content left">
                  <div class="content-body">
                    <img class="timeline-avatar" src="../assets/icons/005-kitty-4.svg" alt="">
                    <div class="timeline-text">
                      <strong>Lieferung</strong>
                      <div>
                        Ich bearbeite deinen Fall innerhalb der nächsten 10 Tage und schicke dir die Ergebnisse per Mail.
                        In den Wochen danach bleiben wir in Kontakt und klären alles, was noch offen ist. Telefon ist immer eine Option, wenn dir das lieber ist.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="offerings" class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged">
            Leistungen
          </h1>
          <h4 class="subtitle">Das kann ich für dich tun</h4>
        </div>
        <b-tabs size="is-medium" v-model="consultationTabIndex" id="offeringtabs" multiline>
          <b-tab-item label="BARF-Plan" icon="drumstick-bite" class="columns">
            <div class="column content">
              <img class="cat-icon" src="../assets/icons/006-kitty-5.svg">
              <p>BARF steht für “bones and raw food” oder zu deutsch “biologisch artgerechte Rohfütterung” und bezeichnet eine Methode des Fütterns selbst
                zusammengestellter
                Mahlzeiten aus rohen Zutaten, vornehmlich für Hunde und Katzen. Analog zum Mensch ist es ist so, als würde man selbst gesundes und leckeres Essen kochen,
                anstatt
                sich von Fertignahrung zu ernähren. Klingt vernünftig, oder?
              </p>
              <strong>Diese Methode hat ein paar entscheidende Vorteile:</strong>
              <ul>
                <li>es ist eine artgerechte Methode der Fütterung</li>
                <li>du hast die Zusammenstellung zu 100% selbst in der Hand - du weißt genau welche Zutaten du verarbeitest und deiner Katze anbietest</li>
                <li>falls die Notwendigkeit besteht, kannst du bei deinem Futter an verschiedenen “Stellschrauben” drehen und das Rezept anpassen, statt auf komplett anderes
                  Futter ausweichen zu müssen
                </li>
                <li>durch die hohe Verwertbarkeit wird der Stoffwechsel weniger belastet und das merkst du auch indem du weniger Geld für Katzenstreu ausgeben musst</li>
                <li>der Entstehung von Allergien kann vorgebeugt werden</li>
                <li>für fast alle Katzen geeignet</li>
              </ul>

              <p>
                Wenn das so toll ist, warum macht das nicht jeder so?
                Ganz klar, es ist nicht so einfach wie Fertignahrung.
              </p>

              <strong>Zum BARFen brauchst du:</strong>
              <ul>
                <li>das entsprechende Know-How, damit deine Katze mit allen nötigen Nährstoffen gut versorgt wird - Ernährungsberater haben das Know-How und können diesen Punkt für
                  dich abdecken
                </li>
                <li>ausreichend Platz im Gefrierer - alternativ musst du öfters “Mischen”</li>
                <li>die Zeit das Futter zuzubereiten</li>
                <li>viel Geduld und Willen für die Futterumstellung und die Gewöhnung an BARF</li>
              </ul>
              <br>
              <strong>Leistungen</strong>
              <div class="columns">
                <p class="column">Wenn du dich für einen BARF-Plan entscheidest, erstelle ich dir einen Fütterungsplan individuell für deine Katze. Damit hast du einen Bauplan in
                  der Hand, mit dem du selbst passende Rezepte kreieren kannst. Ich liefere dir 2 Rezepte als Beispiel zum nachmischen.
                  Wenn du noch Fleisch vorrätig hast oder bestimmte Fleischsorten verarbeiten möchtest, berücksichtige ich das natürlich.<br>Ich gebe dir zahlreiche nützliche
                  Informationen mit auf den Weg, z.B. welche besonderen ernährungsphysiologischen Ansprüche Katzen an ihr Futter haben, welche Nahrungsergänzungsmittel sinnvoll
                  sein
                  können, was du bei Erkrankungen (wenn vorhanden) beachten musst und wie hoch ungefähr deine Futterkosten sein werden.<br>
                  Falls ich der Meinung bin, dass eine Urin- oder Kotuntersuchung bei deiner Katze sinnvoll ist, biete ich dir optional die Labordiagnostik dazu an. Die
                  Laborbefunde
                  werte ich natürlich auch für dich aus. Die Laborkosten sind allerdings nicht im Paket inbegriffen.<br>
                  Falls Probleme bei der Fütterung auftreten stehe ich dir als Ansprechpartner für die nächsten 10 Wochen zur Verfügung.
                </p>
              </div>
            </div>
            <div class="column is-4">
              <div class="flex-card raised secondary-accent-card padding-20 order-box">
                <p class="has-text-centered order-title">BARF-Plan bestellen</p>
                <div class="content">
                  Das bekommst du:
                  <ul>
                    <li> Erstellung eines Futterplans</li>
                    <li> 2 passende Rezepte</li>
                    <li> Hilfestellung bei der Zutatenbeschaffung</li>
                    <li> Berücksichtigung von Erkrankungen</li>
                    <li> Schätzung deiner Futterkosten</li>
                    <li> Unterstützung bei der Umstellung auf BARF</li>
                    <li> zahlreiche Informationen rund um die Katzenernährung wie Merkmale einer artgerechten Ernährung oder Tipps zur Futterumstellung</li>
                    <li> Empfehlung von sinnvollen Nahrungsergänzungsmitteln</li>
                    <li> 10 Wochen Betreuung nach Planlieferung</li>
                    <li> bei Bedarf Labordiagnostik von Urin- oder Kotproben inklusive Auswertung der Befunde (Laborkosten nicht inbegriffen)</li>
                  </ul>
                </div>
                <div class="has-text-centered">
                  <b-button icon-right="arrow-right" type="is-primary" tag="router-link" :to="{ name: 'order-consultation', params: {type: 'barf' } }">Bestellen - 80€</b-button>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Nassfutter-Plan" icon="drum-steelpan" class="columns">
            <div class="column content">
              <img class="napf-icon" src="../assets/icons/012-plate.svg">
              <img class="cat-icon" src="../assets/icons/035-kitty-10.svg">
              <p>Dose auf und rein in den Napf! Die Fütterung von industriell gefertigtem Futter ist immer ein Kompromiss und kann nie perfekt für deine Katze abgestimmt sein.
                Dennoch hat sie ihre Berechtigung, denn nicht jeder möchte oder kann das Futter selbst zusammenstellen.</p>
              <p>
                Diese Methode ist sehr unkompliziert für den Halter und mit der richtigen Dose kannst du deine Katze damit auch gut ernähren. Durch die Vielzahl an Futtersorten ist
                es schwer den Überblick zu behalten und es gibt außerdem enorme Qualitätsunterschiede.<br>Teilweise suggerieren die Bezeichnungen auf der Dose nur das Beste für
                deine
                Samtpfote, in Realität sieht das leider oft ganz anders aus. Um ein Futter gut beurteilen zu können, muss man wissen was hinter den teilweise kryptischen Begriffen
                auf der Packung steckt und wie gut die Zusammensetzung zu den ernährungsphysiologischen Ansprüchen der Katze passt.
              </p>

              <strong>Leistungen</strong>
              <p> Wenn du dich für einen Nassfutter-Plan entscheidest, dann ermittle ich den individuellen Nährstofftagesbedarf deiner Katze nach NRC. Das National Research
                Council (NRC) ist eine amerikanische Organisation die Nährstoffbedarfswerte für Hunde und Katzen aus unterschiedlichen Studien zu Fertigfuttern erhebt.
                Ich empfehle dir verschiedene Nassfuttersorten die du deiner Katze anbieten kannst und zeige dir die Vor- und Nachteile der ausgewählten Futtersorten auf. Ich
                berechne die jeweilige Bedarfsdeckung des Futters für deine Katze, sofern die Herstellerangaben dafür genau genug sind.</p>
              <p>
                Ich teile dir mit, wo du das Futter kaufen kannst und wie hoch deine Kosten ungefähr sein werden. Auch Fertigfutter lässt sich noch mit Nahrungsergänzungsmitteln
                optimieren. Falls das bei deiner Katze der Fall sein könnte, berate ich dich dazu. <br>
                Bei Umstellungsproblemen gebe ich dir Tipps, wie du das neue Futter für
                deine Katze schmackhaft machen kannst - hier ist eventuell viel Geduld gefragt, denn Katzen sind gern etwas mäkelig. Außerdem gebe ich dir eine Checkliste zur Hand,
                anhand derer du gutes von schlechtem Nassfutter unterscheiden kannst.
              </p>
              <p>
                Der gesundheitliche Zustand deiner Katze wird bei meiner Futterauswahl mit einbezogen.
                Allerdings wäre im Erkrankungsfall die <a @click="consultationTabIndex=0" v-scroll-to="'#offeringtabs'">BARF-Fütterung</a> als ernährungsphysiologische
                Unterstützung die bessere Wahl, da man damit die Fütterung ganz individuell gestalten und auf die gesundheitlichen Bedürfnisse deiner Katze anpassen kann.<br>
                Falls ich der Meinung bin, dass eine Urin- oder Kotuntersuchung bei deiner Katze sinnvoll ist, biete ich dir optional die Labordiagnostik dazu an. Die Laborbefunde
                werte ich natürlich auch für dich aus. Die Laborkosten sind allerdings nicht im Paket inbegriffen.<br>
                Ich stehe dir als Ansprechpartner für die nächsten 4 Wochen nach Planlieferung zur Verfügung.
              </p>
            </div>

            <div class="column is-4">
              <div class="flex-card raised secondary-accent-card padding-20 order-box">
                <p class="has-text-centered order-title">Nassfutter-Plan bestellen</p>
                <div class="content">
                  Das bekommst du:
                  <ul>
                    <li> NRC-Bedarfswertermittlung deiner Katze</li>
                    <li> Empfehlung von geeigneten Nassfuttersorten</li>
                    <li> Berechnung der Bedarfsdeckung des Futters, soweit möglich</li>
                    <li> Hilfestellung bei der Händlersuche</li>
                    <li> Berücksichtigung von Erkrankungen</li>
                    <li> Schätzung deiner Futterkosten</li>
                    <li> Empfehlung von sinnvollen Nahrungsergänzungsmitteln</li>
                    <li> Betreuung bei der Umstellung auf das neue Futter</li>
                    <li> Checkliste was gutes Futter ausmacht</li>
                    <li> 4 Wochen Betreuung nach Planlieferung</li>
                    <li> bei Bedarf Labordiagnostik von Urin- oder Kotproben inklusive Auswertung der Befunde (Laborkosten nicht inbegriffen)</li>
                  </ul>
                </div>
                <div class="has-text-centered">
                  <b-button icon-right="arrow-right" type="is-primary" tag="router-link" :to="{ name: 'order-consultation', params: {type: 'wet' } }">Bestellen - 40€</b-button>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Rationsüberprüfung" icon="utensils" class="columns">
            <div class="column content">
              <img class="cat-icon" src="../assets/icons/040-kitty-15.svg">
              <p>Möchtest du wissen wie geeignet die aktuelle Fütterung für deine Katze ist?</p>
              <p>
                Wenn du industriell hergestelltes Futter verwendest, dann ermittle ich den individuellen Nährstofftagesbedarf deiner Katze nach NRC und überprüfe die
                Bedarfsdeckung der aktuellen Fütterung. Das National Research Council (NRC) ist eine amerikanische Organisation die Nährstoffbedarfswerte für Hunde und Katzen aus
                unterschiedlichen Studien zu Fertigfuttern erhebt.<br>
                Wenn du BARFst, prüfe ich wie geeignet die Zusammenstellung des Futters für deine Katze ist.</p>
              <p>Im besten Fall ist deine Katze gut versorgt und alle sind glücklich. Falls Lücken oder Optimierungspotenziale bestehen, erkläre ich dir diese genau. Weiterführend
                berate ich dich gern separat per <a @click="consultationTabIndex=1" v-scroll-to="'#offeringtabs'">Nassfutter</a>- oder <a @click="consultationTabIndex=0"
                                                                                                                                          v-scroll-to="'#offeringtabs'">BARF-Plan</a>
                bezüglich Alternativen zur
                aktuellen Fütterung.</p>
            </div>
            <div class="column is-4">
              <div class="flex-card raised secondary-accent-card padding-20 order-box">
                <p class="has-text-centered order-title">Rationsüberprüfung</p>
                <div class="content">
                  Das bekommst du:
                  <ul>
                    <li> Überprüfung der aktuellen Fütterung auf Eignung für deine Katze</li>
                    <li> Falls vorhanden, Erklärung von Lücken oder Optimierungspotentialen</li>
                  </ul>
                </div>
                <div class="has-text-centered">
                  <b-button icon-right="arrow-right" type="is-primary" tag="router-link" :to="{ name: 'order-consultation', params: {type: 'ration' } }">Bestellen - 25€</b-button>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Ernährungsberatung',
  data: function () {
    return {
      consultationTabIndex: 0
    }
  },
  created () {
    this.$emit('useFixedNavbar', true)
  }
}
</script>

<style lang="scss">
  @import "../app.scss";
  @import "scss/timeline";

  .section {
    background-color: $secondary;
  }
</style>

<style scoped lang="scss">

  .button {
    margin: 0.5rem;
    padding: 0rem 4rem;
  }

  .order-title {
    text-align: center !important;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .cat-icon {
    float: right;
    margin: 2% 1%;
    max-width: 30%;
  }

  .napf-icon {
    float: right;
    margin-top: 14%;
    margin-right: 1%;
    max-width: 19%;
  }

  .order-box {
    position: sticky;
    top: 5rem;
  }
</style>
