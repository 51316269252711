<template>
  <div id="app">
    <Navbar ref="navbar"/>
    <router-view v-on:useFixedNavbar="useFixedNavbar"/>
    <b-message class="privacy is-accent" v-if="!privacy.userHandled">
      <div>
        Wenn du zustimmst, werden Nutzungsdaten erfasst und ausgewertet, u.a. mittels Cookies. In den <a href="#/privacy" class="link-on-dark">Privatsphären-Einstellungen</a>
        erhälst du weitere Informationen und kannst alle Einstellungen ändern.
        <div class="buttons are-small">
          <b-button class="" @click="acceptUsage(false)">Ablehnen</b-button>
          <b-button class="is-success" @click="acceptUsage(true)">Akzeptieren</b-button>
        </div>
      </div>
    </b-message>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Navbar, Footer
  },
  data () {
    return {
      fixedNavbar: false,
      privacy: {
        userHandled: false,
        gaOk: 'Nein'
      }
    }
  },
  methods: {
    useFixedNavbar (value) {
      this.fixedNavbar = value
      this.$refs.navbar.setFixedNavbar(this.fixedNavbar)
    },
    acceptUsage (accepted) {
      this.privacy.userHandled = true
      if (accepted) {
        this.$ga.enable()
        this.privacy.gaOk = 'Ja'
      } else {
        this.privacy.gaOk = 'Nein'
      }
      const privacy = JSON.stringify(this.privacy)
      localStorage.setItem('privacy', privacy)
    }
  },
  created () {
    const privacy = localStorage.getItem('privacy')
    if (privacy) {
      this.privacy = JSON.parse(privacy)
    }

    if (this.privacy.gaOk === 'Ja') {
      this.$ga.enable()
    } else {
      this.$ga.disable()
    }
  }
}
</script>

<style lang="scss">
  @import './app.scss';

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .privacy {
    position: fixed;
    bottom: -1rem;
    right: 1rem;
    z-index: 100;

    .buttons {
      float: right
    }
  }
</style>
