import ax from 'axios'

export const axios = ax

ax.defaults.xsrfCookieName = 'XSRF-TOKEN'
ax.defaults.withCredentials = true

export default {
  install (Vue, options) {
    ax.defaults.xsrfCookieName = 'XSRF-TOKEN'
    Vue.prototype.$axios = ax
    ax.defaults.baseURL = ''
    // ax.defaults.baseURL = 'http://localhost:8085'
  }
}
