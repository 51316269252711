<template>
  <p class="image">
    <img src="../assets/certs/z1.jpg" v-if="src === 'certs/z1'">
    <img src="../assets/certs/z2.jpg" v-if="src === 'certs/z2'">
    <img src="../assets/certs/z3.jpg" v-if="src === 'certs/z3'">
    <img src="../assets/certs/z4.png" v-if="src === 'certs/z4'">
    <img src="../assets/certs/z5.png" v-if="src === 'certs/z5'">
    <img src="../assets/certs/z6.jpg" v-if="src === 'certs/z6'">
    <img src="../assets/certs/z7.png" v-if="src === 'certs/z7'">
    <img src="../assets/certs/z8.jpg" v-if="src === 'certs/z8'">
  </p>

</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    src: String
  }
}
</script>
