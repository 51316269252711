<template>
  <div>
    <table class="table">
      <thead>
      <tr>
        <td><strong>Marke</strong></td>
        <td><strong>Sorte</strong></td>
        <td><strong>Menge in g</strong></td>
        <td><strong>Zeitspanne</strong></td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rows" v-bind:key="index">
        <td>
          <b-input v-model="row.brand"></b-input>
        </td>
        <td>
          <b-input v-model="row.type"></b-input>
        </td>
        <td>
          <b-input v-model="row.amount" @input="dataChanged"></b-input>
        </td>
        <td>
          <b-select v-model="row.interval">
            <option
              v-for="option in intervalOptions"
              :value="option.value"
              :key="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </td>
        <td>
          <b-button icon-left="times" type="is-danger" rounded @click="removeRow(index)"></b-button>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <b-button icon-left="plus" class="button btn-primary" @click="addRow">Eintrag hinzufügen</b-button>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FeedingEntry',
  props: {
    foodType: String,
    entries: Array
  },
  mounted () {
    if (this.entries.length > 0) {
      this.rows = []
      for (let i = 0; i < this.entries.length; i++) {
        this.rows.push(this.entries[i])
      }
    }
  },
  data () {
    return {
      rows: [{ interval: 1 }],
      intervalOptions: [{ label: 'pro Tag', value: 1 }, { label: 'pro Woche', value: 2 }]
    }
  },
  methods: {
    addRow: function () {
      document.createElement('tr')
      this.rows.push({
        interval: 1
      })
    },
    removeRow: function (index) {
      this.rows.splice(index, 1)
    },
    dataChanged: function () {
      this.$emit('dataChanged', this.rows, this.foodType)
    }
  }
}
</script>
