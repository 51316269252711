<template>
  <div class="has-navbar-fixed-top">
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h2 class="section-title is-hidden-mobile">
            Bestellung {{order.name}}
          </h2>
          <h2 class="subsection-title is-hidden-tablet">
            Bestellung {{order.name}}
          </h2>
        </div>

        <b-steps animated ref="steps" id="steps" v-model="activeStep" v-on:input="tryNext">
          <b-step-item label="Infos zu dir" icon="user">
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Infos zu dir
              </h2>
            </div>
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <ValidationObserver ref="contactObserver" tag="form">
                  <b-field horizontal label="Name">
                    <ValidationProvider rules="required" name="Vorname" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.firstName" type="text" placeholder="Vorname">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="Nachname" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.lastName" type="text" placeholder="Nachname">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>

                  <b-field horizontal label="Straße">
                    <ValidationProvider rules="required" name="Straße" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.street" type="text" placeholder="Straße">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="Hausnummer" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.streetNumber" type="text" placeholder="Hausnummer">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>

                  <b-field horizontal label="Ort">
                    <ValidationProvider rules="required|digits:5" name="plz" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.plz" type="text" placeholder="Postleitzahl">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required" name="Ort" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.place" type="text" placeholder="Ort">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>

                  <b-field horizontal label="Kontakt">
                    <ValidationProvider rules="required|email" name="email" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.email" type="text" placeholder="E-Mail-Adresse">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="" name="Telefonnummer" v-slot="{ errors, valid }" slim>
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                        <b-input v-model="person.phone" type="text" placeholder="Telefonnummer (optional)">
                        </b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>
                  <b-field horizontal label="Erfahrung mit Katzen">
                    <div class="level">
                      <span class="is-hidden-tablet">Auf einer Skala von 1 bis 10.</span>
                      <div class="level-left is-hidden-mobile">
                        <p class="level-item">
                          <span class="">keine</span>
                        </p>
                      </div>
                      <p class="level-item padding-30-h">
                        <b-slider v-model="person.experience" :min="1" :max="10" bigger-slider-focus :tooltip="false">
                          <b-slider-tick :value="1">1</b-slider-tick>
                          <b-slider-tick :value="2">2</b-slider-tick>
                          <b-slider-tick :value="3">3</b-slider-tick>
                          <b-slider-tick :value="4">4</b-slider-tick>
                          <b-slider-tick :value="5">5</b-slider-tick>
                          <b-slider-tick :value="6">6</b-slider-tick>
                          <b-slider-tick :value="7">7</b-slider-tick>
                          <b-slider-tick :value="8">8</b-slider-tick>
                          <b-slider-tick :value="9">9</b-slider-tick>
                          <b-slider-tick :value="10">10</b-slider-tick>
                        </b-slider>
                      </p>
                      <div class="level-right is-hidden-mobile">
                        <p class="level-item">
                          <span class="">Katzenprofi</span>
                        </p>
                      </div>
                    </div>
                  </b-field>
                </ValidationObserver>
              </div>
            </div>
          </b-step-item>

          <b-step-item label="Beratungsgrund" icon="question" disabled>
            <div class="section-title-wrapper has-text-centered is-hidden-mobile">
              <h2 class="subsection-title">
                Beratungsgrund
              </h2>
            </div>
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <div class="field" v-for="(option, index) in form.consultationReasons" v-bind:key="index">
                  <b-checkbox v-model="consultation.reasons" :native-value="option">
                    <span>{{ option }}</span>
                  </b-checkbox>
                </div>
                <b-field label="Sonstige Gründe">
                  <b-input type="textarea" v-model="consultation.otherReasons"></b-input>
                </b-field>
                <b-field label="Was ist dir bei der Beratung besonders wichtig? Was sind deine Ziele?">
                  <b-input type="textarea" v-model="consultation.goals"></b-input>
                </b-field>
              </div>
            </div>
          </b-step-item>

          <b-step-item label="Abschicken" icon="check">
            <div class="columns is-centered">
              <div class="column is-two-thirds">
                <h3 class="margin-bottom margin-top">Deine Daten</h3>
                <table class="table">
                  <tr>
                    <td><strong>Name</strong></td>
                    <td>{{person.firstName}} {{person.lastName}}</td>
                  </tr>
                  <tr>
                    <td><strong>Adresse</strong></td>
                    <td>{{person.street}} {{person.streetNumber}}</td>
                  </tr>
                  <tr>
                    <td><strong>Ort</strong></td>
                    <td>{{person.plz}} {{person.place}}</td>
                  </tr>
                  <tr>
                    <td><strong>E-Mail</strong></td>
                    <td>{{person.email}}</td>
                  </tr>
                  <tr>
                    <td><strong>Telefonnummer</strong></td>
                    <td>{{person.phone}}</td>
                  </tr>
                </table>
                Diese Daten verwende ich als Rechnungs- und Lieferadresse.

                <h3 class="margin-bottom margin-top">Dein Paket</h3>
                <table class="table">
                  <tr>
                    <td colspan="2"><strong>{{order.name}}</strong></td>
                  </tr>
                  <tr>
                    <td><strong>Preis</strong></td>
                    <td>{{order.price}}</td>
                  </tr>
                  <tr>
                    <td><strong>Leistungen</strong></td>
                    <td class="content">
                      <ul>
                        <li v-for="(item, index) in order.items" v-bind:key="index">{{item}}</li>
                      </ul>
                    </td>
                  </tr>
                </table>
                <i>Gemäß § 19 UStG wird keine Umsatzsteuer berechnet.</i>
                <h3 class="margin-bottom margin-top">Abschicken</h3>
                Willst du mir noch etwas mitteilen, oder soll ich etwas besonderes zu deiner Bestellung beachten? Schreib es gern
                gleich hier.
                <b-input type="textarea" v-model="order.notes"></b-input>

                <ValidationObserver ref="sendObserver" tag="form" class="margin-top">
                  <ValidationProvider rules="checked" name="Terms" v-slot="{ errors }">
                    <b-field class="field" :type="{ 'is-danger': errors[0]}" :message="errors">
                      <b-checkbox v-model="order.acceptedTerms"></b-checkbox>
                      <span>Ich akzeptiere die <a :href="`${publicPath}docs/AGB_Carnibits_11_04_2020.pdf`" target="_blank">Allgemeinen Geschäftsbedingungen</a></span>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider rules="checked" name="Privacy" v-slot="{ errors }">
                    <b-field class="field" :type="{ 'is-danger': errors[0]}" :message="errors">
                      <b-checkbox v-model="order.acceptedPrivacy"></b-checkbox>
                      <span>Ich akzeptiere die <a :href="`${publicPath}docs/Datenschutzerklärung_11_04_2020.pdf`" target="_blank">Datenschutzerklärung</a></span>
                    </b-field>
                  </ValidationProvider>
                </ValidationObserver>
                <div class="margin-top">
                  <b-button type="is-primary" @click="tryOrder" :class="{ 'is-loading': sendButtonLoading }">Jetzt zahlungspflichtig bestellen</b-button>
                </div>
              </div>
            </div>
          </b-step-item>
          <template slot="navigation" slot-scope="{previous, next}">
            <div class="container has-text-centered padding-20">
              <b-button class="step-control" outlined type="is-primary" icon-left="backward" v-if="!previous.disabled" @click.prevent="previous.action" v-scroll-to="'#steps'">
                Zurück
              </b-button>
              <b-button class="step-control" outlined type="is-primary" icon-right="forward" v-if="!next.disabled" @click.prevent="next.action" v-scroll-to="'#steps'">
                Weiter
              </b-button>
            </div>
          </template>
        </b-steps>
      </div>
    </section>
  </div>
</template>

<script>
import { axios } from '@/plugins/axios'
import * as Sentry from '@sentry/browser'

export default {
  name: 'OrderConsultation',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      activeStep: 0,
      sendButtonLoading: false,
      form: {
        rationItems: ['Überprüfung der aktuellen Fütterung auf Eignung für deine Katze', 'Falls vorhanden, Erklärung von Lücken oder Optimierungspotentialen'],
        wetItems: [' NRC-Bedarfswertermittlung deiner Katze', ' Empfehlung von geeigneten Nassfuttersorten', ' Berechnung der Bedarfsdeckung des Futters, soweit möglich', ' Hilfestellung bei der Händlersuche', ' Berücksichtigung von Erkrankungen', ' Schätzung deiner Futterkosten', ' Empfehlung von sinnvollen Nahrungsergänzungsmitteln', ' Betreuung bei der Umstellung auf das neue Futter', ' Checkliste was gutes Futter ausmacht', ' 4 Wochen Betreuung nach Planlieferung', ' bei Bedarf Labordiagnostik von Urin- oder Kotproben inklusive Auswertung der Befunde (Laborkosten nicht inbegriffen)'],
        barfItems: [' Erstellung eines Futterplans', ' 2 passende Rezepte', ' Hilfestellung bei der Zutatenbeschaffung', ' Berücksichtigung von Erkrankungen', ' Schätzung deiner Futterkosten', ' Unterstützung bei der Umstellung auf BARF', ' zahlreiche Informationen rund um die Katzenernährung wie Merkmale einer artgerechten Ernährung oder Tipps zur Futterumstellung', ' Empfehlung von sinnvollen Nahrungsergänzungsmitteln', ' 10 Wochen Betreuung nach Planlieferung', ' bei Bedarf Labordiagnostik von Urin- oder Kotproben inklusive Auswertung der Befunde (Laborkosten nicht inbegriffen)'],
        consultationReasons: ['Umstellungswunsch auf artgerechte / hochwertige Ernährung', 'durch ärztliche Diagnose wird eine spezielle Diätik empfohlen', 'ungewöhnliches Verhalten oder Beobachtungen', 'vorhandene Allergie oder Nahrungsmittelunverträglichkeit', 'Gewichtsanpassung (Übergewicht / Untergewicht)']
      },
      order: {
        name: '',
        price: '',
        acceptedTerms: false,
        acceptedPrivacy: false,
        notes: '',
        items: [],
        position: ''
      },
      person: {
        experience: 5,
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        place: '',
        plz: '',
        email: '',
        phone: ''
      },
      // person: {
      //   experience: 5,
      //   firstName: 'reee',
      //   lastName: 'habi',
      //   street: 'Wein',
      //   streetNumber: '22',
      //   place: 'Dres',
      //   plz: '01111',
      //   email: 're@ne.de',
      //   phone: '1234 / dfasda'
      // },
      consultation: {
        reasons: [],
        otherReasons: '',
        goals: ''
      }
    }
  },
  mounted () {
    if (this.$route.params.type === 'ration') {
      this.order.name = 'Rationsüberprüfung'
      this.order.price = '25€'
      this.order.items = this.form.rationItems
      this.order.position = '03'
    } else if (this.$route.params.type === 'wet') {
      this.order.name = 'Nassfutter Plan'
      this.order.price = '40€'
      this.order.items = this.form.wetItems
      this.order.position = '02'
    } else if (this.$route.params.type === 'barf') {
      this.order.name = 'BARF Plan'
      this.order.price = '80€'
      this.order.items = this.form.barfItems
      this.order.position = '01'
    }
  },
  methods: {
    tryOrder: async function () {
      const isValid = await this.$refs.sendObserver.validate()
      if (isValid) {
        this.sendButtonLoading = true
        Sentry.setExtra('path', '/order/consultation')
        Sentry.setExtra('order', this.order)
        Sentry.setExtra('person', this.person)
        Sentry.setExtra('consultation', this.consultation)
        axios.post('/order/consultation', {
          order: this.order,
          person: this.person,
          consultation: this.consultation
        }).then(response => {
          if (response.data.errorMessage === '') {
            this.$router.push('/').catch(err => {
              this.ignore = err
            })
            window.scrollTo(0, 0)
            this.$buefy.dialog.alert({
              title: 'Vielen Dank für deine Bestellung!',
              message: 'Deine Bestellnummer ist <br><i>' + response.data.orderNumber + '</i>.<br>' +
                'Du solltest gleich die Bestellbestätigung per Mail bekommen. Dort findest du auch den Link zum Fragebogen. Bitte fülle ihn so gut es geht aus und schicke ihn ab. ' +
                'Danach lege ich los. Falls du zwischenzeitlich Fragen hast, oder es Probleme mit der Bestellung gibt, wende dich jederzeit an mich unter <b>anne@carnibits.de</b>.',
              confirmText: 'Ok',
              scroll: 'keep',
              type: 'is-success'
            })
          } else {
            this.alertCustomError(response.data.errorMessage)
            Sentry.captureMessage('Error from Backend: ' + response.data.errorMessage)
          }
        }).catch(error => {
          this.alertCustomError(error)
          Sentry.captureException(error)
        }).finally(() => (this.sendButtonLoading = false))
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Bitte akzeptiere die AGB und Datenschutzerklärung.',
          position: 'is-bottom',
          type: 'is-danger',
          queue: false
        })
      }
    },
    alertCustomError (error) {
      this.$buefy.dialog.alert({
        title: 'Ein Fehler ist aufgetreten',
        message: 'Entschuldige bitte, es gab ein Problem und die Bestellung konnte nicht abgesendet werden.<br><br>' +
            'Versuche es doch später erneut, oder schreib mir eine Mail an <b>anne@carnibits.de</b>. Tut mir' +
            ' echt Leid!<br><br><i>Das war übrigens der Fehler, falls es dich interessiert<br>' + error + '</i>',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        scroll: 'keep'
      })
    },
    tryNext: async function (arg) {
      const valid = await this.$refs.contactObserver.validate()
      if (!valid) {
        this.$refs.steps.changeStep(0)
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Bitte korrigiere deine Daten.',
          position: 'is-bottom',
          type: 'is-danger',
          queue: false
        })
      }
    }
  },
  created () {
    this.$emit('useFixedNavbar', true)
  }
}
</script>

<style lang="scss">
  @import "../app.scss";

  .section {
    background-color: $secondary;
  }

  #steps {
    padding-top: 1rem;
  }

  .margin-top {
    margin-top: 2rem;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

  .step-control {
    margin-left: 1rem;
    margin-right: 1rem;
  }
</style>

<style scoped lang="scss">
  ul {
    margin: 0 0.5rem 0;
  }
</style>
