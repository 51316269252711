<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-half-mobile">
          <div class="footer-column">
            <div class="footer-header">
              <h3>Angebote</h3>
            </div>
            <ul class="link-list">
              <li><router-link to="/consultation">Ernährungsberatung</router-link></li>
              <li>anne@carnibits.de</li>
            </ul>
          </div>
        </div>

        <div class="column is-half-mobile">
          <div class="footer-column">
            <div class="footer-header">
              <h3>Carnibits</h3>
            </div>
            <ul class="link-list">
              <li><router-link to="/imprint">Impressum</router-link></li>
              <li><a :href="`${publicPath}docs/Datenschutzerklärung_11_04_2020.pdf`" target="_blank">Datenschutzerklärung</a></li>
              <li><router-link to="/privacy">Privatsphäre</router-link></li>
              <li><a :href="`${publicPath}docs/AGB_Carnibits_11_04_2020.pdf`" target="_blank">AGB</a></li>
            </ul>
          </div>
        </div>

        <div class="column is-hidden-mobile">
          <div class="footer-column">
            <div class="footer-logo">
              <img src="../assets/logos/carnibits_colored.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style lang="scss">
  @import "../app.scss";

  /* ==========================================================================
  1. Light Footer
  ========================================================================== */
  footer {
    background: $accent;
    color: $fade-grey;
    position: relative;
    padding: 3rem 10rem 1.5rem;

    .footer-logo {
      padding: 10px 0;

      img {
        height: 150px;
      }
    }

    .footer-column {
      .footer-header {
        padding: 10px 0;

        h3 {
          font-family: 'Nexa Bold', sans-serif;
          font-weight: 400;
          font-size: 1.1rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-bottom: 0 !important;
          color: $smoke-white
        }
      }

      ul.link-list {
        line-height: 30px;
        font-size: 1rem;

        a {
          color: $light-blue-grey;
          font-weight: 400;
          transition: all 0.5s;
        }

        :hover {
          color: $primary;
        }
      }

      .level-item .icon {
        color: $muted-grey;
        transition: all 0.5s;

        i {
          font-size: 20px;
        }

        :hover {
          color: $secondary;
        }
      }

      .copyright {
        padding: 10px 0;
      }
    }
  }
</style>
