<template>
  <b-navbar fixed-top wrapper-class="container" :class="[navbarScrolled ? 'navbar-default' : 'navbar-transparent']">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="../assets/logos/carnibits_minimal.svg" alt="">
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item class="is-slide" tag="router-link" :to="{ path: '/consultation' }">
        Ernährungsberatung
      </b-navbar-item>
      <b-navbar-item class="is-slide" tag="router-link" :to="{ path: '/calculators' }">
        BARF Rechner
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<style scoped lang="scss">
  @import "../app.scss";

  .navbar {
    width: 100%;
    height: 4.6rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.4s;

    &.navbar-default {
      background: $secondary-light;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

      .navbar-item {
        &:focus {
          background-color: transparent;
        }

        &.is-slide {
          border-bottom: 1px solid transparent;

          &:after {
            content: '';
            display: block;
            width: 0;
            min-width: 0;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 10px;
            background: $accent;
            transition: width .3s;
          }

          &:hover {
            background: $secondary-light;
            color: black;

            &:after {
              width: 100%;
            }
          }
        }
      }

      .navbar-brand {
        img {

          &.light-logo {
            display: none;
          }

          &.dark-logo {
            display: block;
          }
        }
      }
    }

    &.navbar-transparent {
      background: transparent;

      .button {
        border-color: $smoke-white;
        color: $smoke-white;

        &:hover {
          background-color: $smoke-white;
          color: $blue-grey;
        }
      }

      .navbar-item {
        color: $smoke-white;

        &:focus {
          background-color: transparent;
        }

        &.is-slide {
          border-bottom: 1px solid transparent;

          &:after {
            content: '';
            display: block;
            width: 0;
            min-width: 0;
            height: 1.6px;
            position: absolute;
            left: 0;
            bottom: 10px;
            background: $smoke-white;
            transition: width .3s;
          }

          &:hover {
            background: transparent;

            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

</style>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      fixedNavbar: false,
      navbarScrolled: false,
      isComponentModalActive: false
    }
  },
  methods: {
    onScroll () {
      this.navbarScrolled = window.scrollY > 100 || this.fixedNavbar
    },
    setFixedNavbar (value) {
      this.fixedNavbar = value
      this.onScroll()
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
