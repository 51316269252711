import { required, email, min, alpha, is, digits, numeric } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Das Feld muss ausgefüllt werden'
})

extend('email', {
  ...email,
  message: 'Es muss eine gültige E-Mail-Adresse eingegeben werden'
})

extend('min', {
  ...min,
  message: 'Es werden mindestens {length} Zeichen benötigt'
})

extend('is', {
  ...is,
  message: 'Es werden mindestens {value} Zeichen benötigt'
})

extend('digits', {
  ...digits,
  message: 'Eingabe muss aus {length} Zahlen bestehen'
})

extend('alpha', {
  ...alpha,
  message: 'Es sind nur Buchstaben erlaubt'
})

extend('numeric', {
  ...numeric,
  message: 'Es sind nur gaze Zahlen erlaubt'
})

extend('checked', {
  message: 'Muss akzeptiert werden',
  // Returns a boolean value
  validate: value => {
    return value
  }
})
