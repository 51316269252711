<template>
  <div class="has-navbar-fixed-top">
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged is-hidden-mobile">
            Fettrechner
          </h1>
          <h1 class="title-winged is-hidden-tablet">
            Fettrechner
          </h1>
          <h4 class="subtitle">Du kennst den idealen Fettgehalt für dein Rezept und willst die passenden Fleischsorten
            mischen?</h4>
        </div>
        <div class="columns is-centered">
          <div class="column is-two-thirds">
            <ValidationObserver ref="fatObserver" tag="form">
              <b-field label="Verwendete Fleischsorten">
                <div>
                  <table class="table is-narrow">
                    <thead>
                    <tr>
                      <td><strong>Fleischsorte</strong></td>
                      <td><strong>Fettgehalt in %</strong></td>
                      <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <b-input v-model="fat.meat1.name" placeholder="Name des ersten Fleischs"></b-input>
                      </td>
                      <td>
                        <b-field>
                          <b-numberinput controls-position="compact" type="is-dark" v-model="fat.meat1.fatness" min="1"
                                         max="100" step="1.0" min-step="0.1" v-on:input="updateFat"></b-numberinput>
                        </b-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b-input v-model="fat.meat2.name" placeholder="Name des zweiten Fleischs"></b-input>
                      </td>
                      <td>
                        <b-field>
                          <b-numberinput controls-position="compact" type="is-dark" v-model="fat.meat2.fatness" min="1"
                                         max="100" step="1.0" min-step="0.1" v-on:input="updateFat"></b-numberinput>
                        </b-field>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-field>
              <b-field label="Gewünschte Gesamtmenge des Fleischs (g)">
                <b-numberinput controls-position="compact" type="is-dark" v-model="fat.totalAmount" min="0"
                               placeholder="Menge"
                               step="100" v-on:input="updateFat"></b-numberinput>
              </b-field>
              <b-field label="Ziel Fettgehalt in %">
                <b-numberinput controls-position="compact" type="is-dark" v-model="fat.targetFat" min="1" max="100"
                               step="1.0" min-step="0.1" v-on:input="updateFat"></b-numberinput>
              </b-field>

              <div>
                <b-field>
                  <p class="control">
                    <b-button expanded type="is-primary" @click="calculateFat">Berechnen</b-button>
                  </p>
                </b-field>
              </div>
            </ValidationObserver>

            <div>
              <b-message v-if="fat.error !== ''" type="is-danger" has-icon>
                {{fat.error}}
              </b-message>
            </div>
          </div>

        </div>
        <div class="columns is-centered" v-if="fat.ran && fat.error === ''">
          <div class="column is-two-thirds">
            <div class="flex-card raised padding-20 secondary-accent-card">
              <h2>Ergebnis</h2>
              <table class="table">
                <thead>
                <tr>
                  <td><strong>Fleischsorte</strong></td>
                  <td><strong>Menge</strong></td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <label>{{fat.meat1.name}}</label>
                  </td>
                  <td>
                    <label>{{fat.meat1.amount}} g</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{fat.meat2.name}}</label>
                  </td>
                  <td>
                    <label>{{fat.meat2.amount}} g</label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged is-hidden-mobile">
            Jodrechner
          </h1>
          <h1 class="title-winged is-hidden-tablet">
            Jodrechner
          </h1>
          <h4 class="subtitle">Berechne den Jodbedarf deiner ausgewachsenen Katzen.</h4>
        </div>
        <div class="columns is-centered">
          <div class="column is-one-third">
            <b-field label="Jodgehalt des Seealgenmehls in mg/kg">
              <b-numberinput type="is-dark" v-model="jod.jodPerKg" min="1" max="1000"
                             step="1.0" v-on:input="updateJod"></b-numberinput>
            </b-field>
            <table class="table">
              <thead>
              <tr>
                <td><strong>Katze</strong></td>
                <td><strong>Gewicht in kg</strong></td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in rows" v-bind:key="index">
                <td>
                  <label>Katze {{index+1}}</label>
                </td>
                <td>
                  <b-numberinput type="is-dark" v-model="row.weight" min="0.1"
                                 max="100" step="0.5" min-step="0.1" v-on:input="updatedRows"></b-numberinput>
                </td>
                <td>
                  <b-button icon-left="times" type="is-danger" rounded @click="removeJodRow(index)"></b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <b-field class="has-text-right">
              <b-button icon-left="plus" class="button btn-primary" @click="addJodRow">weitere Katze</b-button>
            </b-field>
            <b-field label="Hochrechnung auf wie viele Tage?">
              <b-numberinput type="is-dark" v-model="days" min="1" max="1000"
                             step="1.0" min-step="0.5" v-on:input="updatedRows"></b-numberinput>
            </b-field>
          </div>
        </div>
        <div class="columns is-centered" v-if="jod.ran && jod.error === ''">
          <div class="column is-two-thirds">
            <div class="flex-card raised padding-20 secondary-accent-card">
              <h2>Ergebnis</h2>
              <table class="table">
                <thead>
                <tr>
                  <td>
                    <label></label>
                  </td>
                  <td>
                    <label>täglicher Jodbedarf</label>
                  </td>
                  <td>
                    <label>Tagesportion Seealgenmehl</label>
                  </td>
                  <td>
                    <label>Seealgenmehl für {{days}} Tage</label>
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in rows" v-bind:key="index">
                  <td>
                    <label>Katze {{index+1}}</label>
                  </td>
                  <td>
                    <label>{{row.jodNeed}} &mu;g</label>
                  </td>
                  <td>
                    <label>{{row.jodPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(row.jodPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Durchschnitt</label>
                  </td>
                  <td>
                    <label>{{jod.avgJod}} &mu;g</label>
                  </td>
                  <td>
                    <label>{{jod.avgPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(jod.avgPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Summe</label>
                  </td>
                  <td>
                    <label>{{jod.sumJod}} &mu;g</label>
                  </td>
                  <td>
                    <label>{{jod.sumPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(jod.sumPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="section-title-wrapper has-text-centered">
          <h1 class="title-winged is-hidden-mobile">
            Kalziumrechner
          </h1>
          <h1 class="title-winged is-hidden-tablet">
            Kalziumrechner
          </h1>
          <h4 class="subtitle">Berechne den Kalziumbedarf deiner ausgewachsenen Katzen.</h4>
        </div>
        <div class="columns is-centered">
          <div class="column is-one-third">
            <b-field label="Kalziumgehalt des Supplements in %">
              <b-numberinput type="is-dark" v-model="calc.calcPercentage" min="1" max="100"
                             step="1.0" min-step="0.1" v-on:input="updateCalc"></b-numberinput>
            </b-field>
            <table class="table">
              <thead>
              <tr>
                <td><strong>Katze</strong></td>
                <td><strong>Gewicht in kg</strong></td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in rows" v-bind:key="index">
                <td>
                  <label>Katze {{index+1}}</label>
                </td>
                <td>
                  <b-numberinput type="is-dark" v-model="row.weight" min="0.1"
                                 max="100" step="0.5" min-step="0.1" v-on:input="updatedRows"></b-numberinput>
                </td>
                <td>
                  <b-button icon-left="times" type="is-danger" rounded @click="removeCalcRow(index)"></b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <b-field class="has-text-right">
              <b-button icon-left="plus" class="button btn-primary" @click="addCalcRow">weitere Katze</b-button>
            </b-field>
            <b-field label="Hochrechnung auf wie viele Tage?">
              <b-numberinput type="is-dark" v-model="days" min="1" max="1000"
                             step="1.0" min-step="0.5" v-on:input="updatedRows"></b-numberinput>
            </b-field>
          </div>
        </div>
        <div class="columns is-centered" v-if="calc.ran && calc.error === ''">
          <div class="column is-two-thirds">
            <div class="flex-card raised padding-20 secondary-accent-card">
              <h2>Ergebnis</h2>
              <table class="table">
                <thead>
                <tr>
                  <td>
                    <label></label>
                  </td>
                  <td>
                    <label>täglicher Kalziumbedarf</label>
                  </td>
                  <td>
                    <label>Tagesmenge Kalziumsupplement</label>
                  </td>
                  <td>
                    <label>Kalziumsupplement für {{days}} Tage</label>
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in rows" v-bind:key="index">
                  <td>
                    <label>Katze {{index+1}}</label>
                  </td>
                  <td>
                    <label>{{row.calcNeed}} mg</label>
                  </td>
                  <td>
                    <label>{{row.calcPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(row.calcPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>
                    <label>Durchschnitt</label>
                  </td>
                  <td>
                    <label>{{calc.avgCalc}} mg</label>
                  </td>
                  <td>
                    <label>{{calc.avgPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(calc.avgPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Summe</label>
                  </td>
                  <td>
                    <label>{{calc.sumCalc}} mg</label>
                  </td>
                  <td>
                    <label>{{calc.sumPortion}} g</label>
                  </td>
                  <td>
                    <label>{{Math.round(calc.sumPortion*days*100)/100}} g</label>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'Ernährungsberatung',
  data: function () {
    return {
      consultationTabIndex: 0,
      fat: {
        totalAmount: 2000,
        targetFat: 12,
        meat1: { name: '', fatness: 10, amount: 0 },
        meat2: { name: '', fatness: 15, amount: 0 },
        error: '',
        ran: false
      },
      jod: {
        jodPerKg: 10.0,
        avgJod: 0.0,
        sumJod: 0.0,
        avgPortion: 0.0,
        sumPortion: 0.0,
        error: '',
        ran: false
      },
      calc: {
        calcPercentage: 10.0,
        avgCalc: 0.0,
        sumCalc: 0.0,
        avgPortion: 0.0,
        sumPortion: 0.0,
        error: '',
        ran: false
      },
      rows: [{ weight: 4.5 }],
      days: 14
    }
  },
  methods: {
    calculateFat: async function calculateFat () {
      this.fat.ran = true
      this.fat.targetFat = Math.min(100, Math.max(1, this.fat.targetFat))
      this.fat.meat1.fatness = Math.min(100, Math.max(1, this.fat.meat1.fatness))
      this.fat.meat2.fatness = Math.min(100, Math.max(1, this.fat.meat2.fatness))

      const valid = await this.$refs.fatObserver.validate()
      if (!valid) {
        this.fat.error = 'Bitte Feldvalidierung beachten'
        return
      }
      this.fat.error = ''
      if (this.fat.targetFat > this.fat.meat1.fatness && this.fat.targetFat > this.fat.meat2.fatness) {
        this.fat.error = 'Berechnung nicht möglich. Die angegebenen Fleischsorten haben beide weniger Fett als in der Mischung enthalten sein soll. ' +
            'Der maximale Fettgehalt einer Mischung dieser Fleischsorten ist ' + Math.max(this.fat.meat1.fatness, this.fat.meat2.fatness) + '%.'
        return
      }
      if (this.fat.targetFat < this.fat.meat1.fatness && this.fat.targetFat < this.fat.meat2.fatness) {
        this.fat.error = 'Berechnung nicht möglich. Die angegebenen Fleischsorten haben beide mehr Fett als in der Mischung enthalten sein soll. ' +
            'Der minimale Fettgehalt einer Mischung dieser Fleischsorten ist ' + Math.min(this.fat.meat1.fatness, this.fat.meat2.fatness) + '%.'
        return
      }
      const totalFat = this.fat.totalAmount * this.fat.targetFat * 0.01
      const xMax = totalFat / (this.fat.meat1.fatness * 0.01)
      const fatRel = this.fat.meat2.fatness / this.fat.meat1.fatness
      let amount1 = xMax - this.fat.totalAmount * fatRel
      amount1 = amount1 / (1 - fatRel)
      this.fat.meat1.amount = Math.round(amount1)
      this.fat.meat2.amount = Math.round(this.fat.totalAmount - amount1)
    },
    updateFat: function updateFat () {
      if (this.fat.ran) {
        this.calculateFat()
      }
    },
    updateJod: function updateJod () {
      this.jod.ran = true
      const d = this
      d.jod.sumJod = 0
      d.jod.sumPortion = 0
      this.rows.forEach(function (part, index, theArray) {
        const entry = theArray[index]
        const need = 35 * Math.pow(entry.weight, 0.67)
        entry.jodNeed = Math.round(need * 10) / 10
        d.jod.sumJod += need
        const portion = need / (d.jod.jodPerKg)
        entry.jodPortion = Math.round(portion * 100) / 100
        d.jod.sumPortion += portion
        theArray[index] = entry
      })
      this.jod.avgJod = this.jod.sumJod / this.rows.length
      this.jod.avgPortion = this.jod.sumPortion / this.rows.length
      this.jod.avgJod = Math.round(this.jod.avgJod * 10) / 10
      this.jod.avgPortion = Math.round(this.jod.avgPortion * 100) / 100
      this.jod.sumJod = Math.round(this.jod.sumJod * 10) / 10
      this.jod.sumPortion = Math.round(this.jod.sumPortion * 100) / 100
    },
    addJodRow: function () {
      document.createElement('tr')
      this.rows.push({
        weight: 4.5
      })
      this.updateJod()
      if (this.calc.ran) {
        this.updateCalc()
      }
    },
    removeJodRow: function (index) {
      this.rows.splice(index, 1)
      this.updateJod()
      if (this.calc.ran) {
        this.updateCalc()
      }
    },
    updateCalc: function updateCalc () {
      this.calc.ran = true
      const d = this
      d.calc.sumCalc = 0
      d.calc.sumPortion = 0
      this.rows.forEach(function (part, index, theArray) {
        const entry = theArray[index]
        const need = 71 * Math.pow(entry.weight, 0.67)
        entry.calcNeed = Math.round(need * 10) / 10
        d.calc.sumCalc += need
        const portion = need / (10 * d.calc.calcPercentage)
        entry.calcPortion = Math.round(portion * 100) / 100
        d.calc.sumPortion += portion
        theArray[index] = entry
      })
      this.calc.avgCalc = this.calc.sumCalc / this.rows.length
      this.calc.avgPortion = this.calc.sumPortion / this.rows.length
      this.calc.avgCalc = Math.round(this.calc.avgCalc * 10) / 10
      this.calc.avgPortion = Math.round(this.calc.avgPortion * 100) / 100
      this.calc.sumCalc = Math.round(this.calc.sumCalc * 10) / 10
      this.calc.sumPortion = Math.round(this.calc.sumPortion * 100) / 100
    },
    addCalcRow: function () {
      document.createElement('tr')
      this.rows.push({
        weight: 4.5
      })
      this.updateCalc()
      if (this.jod.ran) {
        this.updateJod()
      }
    },
    removeCalcRow: function (index) {
      this.rows.splice(index, 1)
      this.updateCalc()
      if (this.jod.ran) {
        this.updateJod()
      }
    },
    updatedRows: function (index) {
      if (this.calc.ran) {
        this.updateCalc()
      }
      if (this.jod.ran) {
        this.updateJod()
      }
    }
  },
  created () {
    this.$emit('useFixedNavbar', true)
  }
}
</script>

<style lang="scss">
  @import "../app.scss";

  .section {
    background-color: $secondary;
  }
</style>

<style scoped lang="scss">

</style>
