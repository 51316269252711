<template>
  <div class="has-navbar-fixed-top">
    <section class="has-text-centered">
      <div class="genki">
        <h1 class="parallax-title">Gesunde Ernährung für Katzen</h1>
        <h2 class="parallax-subtitle">
          Damit es deinen Samtpfoten gut geht
        </h2>
        <br>
        <p >
          <a v-scroll-to="'#nutritional-consulting'" class="button button-cta primary-btn rounded">
            Schau's dir an
          </a>
        </p>
      </div>
      <parallax fixed>
        <img src="../assets/genki-background.jpg">
      </parallax>
    </section>

    <div class="has-background-secondary">
      <section id="nutritional-consulting" class="section section-secondary is-medium">
        <div class="container">
          <div class="section-title-wrapper has-text-centered">
            <h1 class="title-winged">
              Beratung
            </h1>
            <h4 class="subtitle">Das kannst du von mir erwarten</h4>
          </div>
        </div>

        <!-- Content -->
        <div class="content-wrapper">
          <div class="columns is-vcentered">
            <div class="column is-4 is-offset-2">
              <div class="content">
                <h2 class="feature-headline is-clean no-margin-bottom">Du bist was du isst - das gilt auch für deine Katzen!</h2>
                <p>Es gibt eine schier unüberschaubare Menge an Fertigfutter für
                  Katzen. Da den Überblick zu behalten ist schwierig.
                  Ich helfe dir dabei das richtige Futter für deine Katzen zu finden. </p>
                <img id="icon-huge-left" class="" src="../assets/icons/018-kitty-31.svg">
                <p>
                  Leider gibt es das "perfekte" Katzenfutter nicht fertig aus der Dose. Wenn du bereit dafür bist, das Futter für deine Katzen
                  selbst zuzubereiten, unterstütze ich dich dabei gern.
                </p>
                <p>
                  Besonders bei Erkrankungen ist es sinnvoll die Ernährung entsprechend anzupassen, um die Genesung zu unterstützen
                  oder aber ein Voranschreiten der Erkrankung abzubremsen.
                </p>
                <div class="pb-10 pt-10 has-text-centered">
                  <b-button tag="router-link" :to="{ path: '/consultation' }" class="button button-cta btn-align raised primary-btn rounded">
                    Mehr erfahren
                  </b-button>
                </div>
              </div>
            </div>
            <!-- Card with icons -->
            <div class="column is-3 is-offset-1">
              <div class="flex-card raised padding-20 secondary-accent-card">
                <div class="icon-group">
                  <span class="icon is-large"><img src="../assets/icons/magnifying-glass.svg"></span>
                  <span>Überprüfung der aktuellen Ernährung</span>
                </div>
                <div class="icon-group">
                  <span class="icon is-large"><img src="../assets/icons/checklist.svg"></span>
                  <span>Erstellung individueller Futterpläne</span>
                </div>
                <div class="icon-group">
                  <span class="icon is-large"><img src="../assets/icons/plate.svg"></span>
                  <span>Hilfe bei der Futterumstellung</span>
                </div>
                <div class="icon-group">
                  <span class="icon is-large"><img src="../assets/icons/money.svg"></span>
                  <span>Schätzung der Futterkosten</span>
                </div>
                <div class="icon-group">
                  <span class="icon is-large"><img src="../assets/icons/vaccination.svg"></span>
                  <span>Berücksichtigung von Erkrankungen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="aboutme" class="section section-accent is-medium is-relative is-skewed-sm">
        <div class="container is-reverse-skewed-sm">
          <b-tabs type="is-boxed" class="tab-title-on-accent" multiline>
            <b-tab-item>
              <template slot="header">
                <img class="icon is-medium" src="../assets/icons/gynecologist.svg">
                <span>Über mich</span>
              </template>
              <div class="about-content">
                <div class="columns is-vcentered is-centered">
                  <div class="column is-3">
                    <img src="../assets/team/anne500.jpg" alt="Anne">
                  </div>
                  <div class="column">
                    Hallo, ich heiße Anne und ich liebe Katzen! Das reicht noch nicht? Also ...<br><br>
                    Ich lebe gefühlt schon immer mit ihnen zusammen und möchte sie auch nie mehr missen. Daher liegt es mir nah, das ich ihnen ein
                    möglichst schönes und langes Leben bieten möchte.<br>
                    Als ich 2011 mit meiner ersten Wohnung meine ersten "eigenen" Katzen halten konnte, begann ich mich intensiver mit artgerechter
                    Haltung und vor allem der Fütterung auseinanderzusetzen.<br>
                    Seit dem lerne ich immer neue Dinge dazu, es ist fast wie eine Sucht die mir unglaublich viel Spass macht.
                    Ich beschloss 2019 Nägel mit Köpfen zu machen und begann offiziell eine Ausbildung als Ernähungsberater für Katzen. Weitere Aus-
                    und Weiterbildung sollten schon bald folgen.<br><br>
                    Ich möchte alle Katzenbesitzer ermutigen sich mit der Ernährung ihrer Katzen auseinanderzusetzen, kritisch zu hinterfragen und im
                    Sinne des Tieres optimal anzupassen.<br>
                    Auf das unsere schnurrenden Freunde viele schöne Lebensjahre mit uns zusammen genießen können!
                  </div>
                  <div class="column is-3 has-text-centered">
                    <img class="image" src="../assets/logos/Siegel_EB_BarfGutAkademie.png">
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <img class="icon is-medium" src="../assets/icons/paw.svg">
                <span>Mein Team</span>
              </template>
              <div class="about-content">
                <p>
                  Das ist mein persönliches Team. Spezialisiert auf olfaktorische und gustatorische Überprüfung verschiedener Nahrungsmittel, vorzugsweise rohes Fleisch.
                  Sie erledigen ihre Aufgaben stets selbstständig, mit äusserster Sorgfalt und Genauigkeit, zeigen außergewöhnliches Engagement und sind im höchsten Maße
                  zuverlässig.
                  Ein besseres Team kann ich mir kaum vorstellen! Danke, dass ihr mich unterstützt!
                </p>
                <div class="modern-team">
                  <article class="modern-team-item circle-mask zoom-effect">
                    <div class="item-wrapper">
                      <div class="item-img">
                        <img src="../assets/team/bilbo.jpg" class="member-avatar">
                      </div>
                      <div class="overlay-wrapper">
                        <div class="overlay-quote has-text-centered">
                          <i class="fa fa-quote-right pull-left"></i>
                          Geschmackssache sagte der Affe und biss in die Seife.
                          <i class="fa fa-quote-left pull-right"></i>
                        </div>
                      </div>
                    </div>
                    <div class="member-info">
                      <h3 class="member-name"><strong>Bilbo</strong> Beutlin</h3>
                      <span class="member-position">Meisterbiebs</span>
                    </div>
                  </article>
                  <article class="modern-team-item circle-mask zoom-effect">
                    <div class="item-wrapper">
                      <div class="item-img">
                        <img src="../assets/team/emil.jpg" class="member-avatar">
                      </div>
                      <div class="overlay-wrapper">
                        <div class="overlay-quote has-text-centered">
                          <i class="fa fa-quote-right pull-left"></i>
                          Ich achte sehr auf meine Ernährung. Was mir schmeckt, das esse ich!
                          <i class="fa fa-quote-left pull-right"></i>
                        </div>
                      </div>
                    </div>
                    <div class="member-info">
                      <h3 class="member-name"><strong>Emil</strong>, einfach Emil</h3>
                      <span class="member-position">Ist auch da</span>
                    </div>
                  </article>
                  <article class="modern-team-item circle-mask zoom-effect">
                    <div class="item-wrapper">
                      <div class="item-img">
                        <img src="../assets/team/genki.jpg" class="member-avatar">
                      </div>
                      <div class="overlay-wrapper">
                        <div class="has-text-centered">
                          <i class="fa fa-quote-right pull-left"></i>
                          Appetit kommt beim essen.
                          <i class="fa fa-quote-left pull-right"></i>
                        </div>
                      </div>
                    </div>
                    <div class="member-info">
                      <h3 class="member-name"><strong>Genki</strong> Langarm</h3>
                      <span class="member-position">Rudelführer</span>
                    </div>
                  </article>
                  <article class="modern-team-item circle-mask zoom-effect">
                    <div class="item-wrapper">
                      <div class="item-img">
                        <img src="../assets/team/nebu.jpg" class="member-avatar">
                      </div>
                      <div class="overlay-wrapper">
                        <div class="overlay-quote has-text-centered">
                          <i class="fa fa-quote-right pull-left"></i>
                          In der Not frisst der Teufel die Wurst auch ohne Brot!
                          <i class="fa fa-quote-left pull-right"></i>
                        </div>

                      </div>
                    </div>
                    <div class="member-info">
                      <h3 class="member-name"><strong>Nebu</strong>Katznezar</h3>
                      <span class="member-position">Hat jeden lieb</span>
                    </div>
                  </article>
                </div>
              </div>

            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <img class="icon is-medium" src="../assets/icons/diploma.svg">
                <span>Qualifikationen</span>
              </template>
              <div class="about-content">
                <p>Damit ich immer ein kompetenter Ansprechpartner für dich sein kann, bilde ich mich regelmäßig weiter. Hier siehst du welche Ausbildungen ich bisher abgeschlossen
                  habe und in welchen ich mich gerade befinde.</p>
                <b-table :data="learningData">
                    <b-table-column field="schulung" label="Schulung" v-slot="props">
                      {{ props.row.schulung }}
                    </b-table-column>
                    <b-table-column field="anbieter" label="Anbieter" v-slot="props">
                      {{ props.row.anbieter }}
                    </b-table-column>
                    <b-table-column field="dozent" label="Dozent" v-slot="props">
                      {{ props.row.dozent }}
                    </b-table-column>
                    <b-table-column field="abschluss" label="Abschluss" v-slot="props">
                      {{ props.row.abschluss }}
                    </b-table-column>
                    <b-table-column field="zertifikat" label="Zertifikat" v-slot="props">
                      <a @click="imageModal( props.row.zertifikat)" v-if="props.row.zertifikat !== ''">öffnen</a>
                      <span v-else>folgt noch :)</span>
                    </b-table-column>
                </b-table>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </section>

      <section id="contact" class="section section-secondary is-medium">
        <div class="container">
          <div class="section-title-wrapper has-text-centered">
            <h1 class="title-winged">
              Hast du Fragen?
            </h1>
            <h4 class="subtitle">Kein Problem, schreib mir einfach kurz dein Anliegen.</h4>
          </div>
          <div class="columns form-wrapper">
            <div class="column is-4 is-offset-2">
              <img id="icon-huge" src="../assets/icons/002-kitty-46.svg">
            </div>
            <contact class="column is-5 is-offset-0">
            </contact>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Parallax from 'vue-parallaxy'
import Contact from '../components/Contact'
import ImageModal from '../components/ImageModal'

export default {
  data: function () {
    return {
      learningData: [
        {
          schulung: 'Ernährungsberaterin Hund / Katze',
          anbieter: 'BARF-GUT-Akademie',
          dozent: 'Ute Wadehn',
          abschluss: 'November 2020',
          zertifikat: 'certs/z6'
        },
        {
          schulung: 'Zahngesundheit bei Katzen',
          anbieter: 'Heilpraktiker- und Therapeutenschule Isolde Richter',
          dozent: 'Michael Geneschen',
          abschluss: 'September 2020',
          zertifikat: 'certs/z7'
        },
        {
          schulung: 'Die spannende Welt der Labordiagnostik',
          anbieter: 'Katzvard - Akademie für Katzenthemen',
          dozent: 'Anika Abel',
          abschluss: 'Januar 2021',
          zertifikat: 'certs/z8'
        },
        {
          schulung: 'Schilddrüsenüberfunktion bei der Katze',
          anbieter: 'Heilpraktiker- und Therapeutenschule Isolde Richter',
          dozent: 'Michael Geneschen',
          abschluss: 'Januar 2020',
          zertifikat: 'certs/z4'
        },
        { schulung: 'Ernährungsberater für Katzen', anbieter: 'Rolf-Schneider-Akademie', dozent: 'Birgit Vorndran', abschluss: 'Juni 2019', zertifikat: 'certs/z5' },
        {
          schulung: 'Katzen barfen - die Einführung',
          anbieter: 'Katzvard - Akademie für Katzenthemen',
          dozent: 'Anika Abel & Silke Falkus',
          abschluss: 'Juni 2019',
          zertifikat: 'certs/z1'
        },
        {
          schulung: 'Katzen barfen - die Fortsetzung',
          anbieter: 'Katzvard - Akademie für Katzenthemen',
          dozent: 'Anika Abel & Silke Falkus',
          abschluss: 'Juni 2019',
          zertifikat: 'certs/z2'
        },
        {
          schulung: 'Katzen barfen - Spezial',
          anbieter: 'Katzvard - Akademie für Katzenthemen',
          dozent: 'Anika Abel & Silke Falkus',
          abschluss: 'Juni 2019',
          zertifikat: 'certs/z3'
        }
      ]
    }
  },
  components: {
    Parallax, Contact
  },
  methods: {
    imageModal (src) {
      this.$buefy.modal.open({
        component: ImageModal,
        props: { src },
        scroll: 'keep',
        parent: this
      })
    }
  },
  created () {
    this.$emit('useFixedNavbar', false)
  }
}
</script>

<style lang="scss">
  @import "../app.scss";

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #icon-huge {
    max-width: 250px;
  }

  @media (max-width: 768px) {
    #icon-huge {
      max-width: 120px;
    }
  }

  #icon-huge-left {
    float: right;
    margin: 2% 1%;
    max-width: 30%;
  }

  .shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
  }

  .about-content {
    p {
      padding: 1em 0em;
    }
  }

  .genki{
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
  }

  .parallax-title {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 3.4rem;
  }

  .parallax-subtitle {
    color: white;
    text-align: center;
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
    .genki {
      top: 15%;
    }
  }

  .modern-team {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    //Team member
    .modern-team-item {
      padding: 0px 10px;

      .item-wrapper {
        position: relative;
      }

      .item-img {
        position: relative;
        overflow: hidden;

        &:before {
          content: "";
          background-color: rgba(224, 153, 32, 0.5);
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
        }
      }

      //Overlay
      .overlay-wrapper {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        opacity: 0;
        will-change: opacity;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        font-size: 20px;
      }

      //Meta
      .member-info {
        color: $fade-grey;
        padding: 10px 10px;

        .member-name {
          font-family: 'Nexa Light', sans-serif;
          font-size: 1.2em;
          margin-top: 0;
          margin-bottom: 0;
          text-transform: uppercase;
          color: $input-grey;

          strong {
            color: $fade-grey;
            font-family: 'Nexa Bold', sans-serif;
          }
        }

        .member-position {
          display: block;
          margin: .5em 0;
          font-family: 'Nexa Light', sans-serif;
          font-size: .8em;
          text-transform: uppercase;
          color: $primary;
        }
      }

      &:hover .overlay-wrapper {
        opacity: 1;
        height: 100%;
        transition: opacity .4s cubic-bezier(0.71, 0.05, 0.29, 0.9) .2s;
      }

      //Circle mask overlay
      &.circle-mask .item-img:before {
        width: 0;
        height: 0;
        padding: 25%;
        border-radius: 50%;
        transition: transform .3s ease, opacity .3s ease-out;
        will-change: opacity, transform;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
      }

      &.circle-mask:hover .item-img:before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(4);
        transition-duration: .6s;
      }

      //zoom effect
      &.zoom-effect .member-avatar {
        transition: transform .4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
        will-change: transform;
        transform: scale(1);
      }

      &.zoom-effect:hover .member-avatar {
        transform: scale(1.2);
      }
    }
  }
</style>
